import { Server } from "lucide-react";
import styles from "./pricing-table.module.css";

export default function PricingTable() {
  const serverConfigs = [
    {
      processor: "E3-1246 V3",
      processorType: "Intel Xeon",
      ram: "64 GB RAM",
      ramType: "DDR3",
      storage: "2x 2 TB HDD",
      storageExtra: "2x 256 GB SSD",
      bandwidth: "1 Gbps Bandwidth",
      price: "19,740",
    },
    {
      processor: "E3-1275v5",
      processorType: "Intel Xeon",
      ram: "64 GB RAM",
      ramType: "DDR4 ECC",
      storage: "2x 4 TB HDD",
      storageExtra: "2x 512 SSD NVMe",
      bandwidth: "1 Gbps Bandwidth",
      price: "19,740",
    },
    {
      processor: "E5-1650V3",
      processorType: "Intel Xeon",
      ram: "128 GB RAM",
      ramType: "DDR4 ECC",
      storage: "2x 960 SSD",
      storageExtra: null,
      bandwidth: "1 Gbps Bandwidth",
      price: "22,560",
    },
    {
      processor: "Core i7-7700",
      processorType: "Intel Core i7",
      ram: "64 GB RAM",
      ramType: "DDR4",
      storage: "2x 4 TB HDD",
      storageExtra: "2x 512 SSD",
      bandwidth: "1 Gbps Bandwidth",
      price: "22,560",
    },
    {
      processor: "EPYC 7401P",
      processorType: "AMD",
      ram: "128 GB RAM",
      ramType: "DDR4 ECC",
      storage: "2x 960 SSD",
      storageExtra: "2x 1966 GB SSD",
      bandwidth: "1 Gbps Bandwidth",
      price: "42,300",
    },
    {
      processor: "EPYC 7401P",
      processorType: "AMD",
      ram: "128 GB RAM",
      ramType: "DDR4 ECC",
      storage: "2x 960 SSD",
      storageExtra: "2x 1966 GB SSD",
      bandwidth: "1 Gbps Bandwidth",
      price: "42,300",
    },
    {
      processor: "EPYC 7401P",
      processorType: "AMD",
      ram: "128 GB RAM",
      ramType: "DDR4 ECC",
      storage: "2x 960 SSD",
      storageExtra: "2x 1966 GB SSD",
      bandwidth: "1 Gbps Bandwidth",
      price: "42,300",
    },
    {
      processor: "EPYC 7401P",
      processorType: "AMD",
      ram: "128 GB RAM",
      ramType: "DDR4 ECC",
      storage: "2x 960 SSD",
      storageExtra: "2x 1966 GB SSD",
      bandwidth: "1 Gbps Bandwidth",
      price: "42,300",
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>High Performance, Low Price</h1>
        <p>Our dedicated servers offer top-tier performance, reliability, and security, ensuring your business runs smoothly with the best hardware configurations.</p>
      </div>

      <div className={styles.tableContainer}>
        {serverConfigs.map((config, index) => (
          <div key={index} className={styles.row}>
            <div className={styles.specGroup}>
              <div className={styles.spec}>
                <Server className={styles.icon} size={20} />
                <div>
                  <div className={styles.primary}>{config.processor}</div>
                  <div className={styles.secondary}>{config.processorType}</div>
                </div>
              </div>

              <div className={styles.spec}>
                <div className={styles.memoryIcon}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M4 6h16v12H4z" />
                    <path d="M8 6v12" />
                    <path d="M16 6v12" />
                  </svg>
                </div>
                <div>
                  <div className={styles.primary}>{config.ram}</div>
                  <div className={styles.secondary}>{config.ramType}</div>
                </div>
              </div>

              <div className={styles.spec}>
                <div className={styles.storageIcon}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <circle cx="12" cy="12" r="10" />
                    <path d="M12 6v12" />
                    <path d="M6 12h12" />
                  </svg>
                </div>
                <div>
                  <div className={styles.primary}>{config.storage}</div>
                  {config.storageExtra && <div className={styles.secondary}>{config.storageExtra}</div>}
                </div>
              </div>

              <div className={styles.spec}>
                <div className={styles.networkIcon}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M5 12h14" />
                    <path d="M12 5l7 7-7 7" />
                  </svg>
                </div>
                <div>
                  <div className={styles.primary}>{config.bandwidth}</div>
                  <div className={styles.secondary}>Unlimited</div>
                </div>
              </div>
            </div>

            <div className={styles.pricing}>
              <div className={styles.priceGroup}>
                <div className={styles.price}>Rs{config.price} /m</div>
                <div className={styles.setupFee}>No Setup Fee</div>
              </div>
              <button className={styles.configureButton}>Configure</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
