import React from 'react'
import { FaRegHardDrive} from "react-icons/fa6";
import { MdAttachMoney, MdOutlineLocalPhone, MdSecurity, MdSupportAgent } from "react-icons/md";
import HostingGrid from '../components/HostingCard';
import { FaGears } from "react-icons/fa6";
import { SiCpanel } from "react-icons/si";

import { FaCogs, FaGlobeAmericas, FaLock, FaRegHandshake, FaRocket, FaServer, FaShieldAlt } from "react-icons/fa";

import {  IoSpeedometerSharp } from "react-icons/io5";


import { SiMinutemailer } from "react-icons/si";

import { LuPanelTop } from "react-icons/lu";
import { MdEmail } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import Topfeature from '../components/Topfeature';
import QASection from '../components/Questionmaries';
import DemoBox from '../components/DemonBox';
// import Features from '../components/PeakFeature';
import CustomerReviews from '../components/CustomerReviews';
import { Helmet } from 'react-helmet';
import HomePricingComparision from '../components/HomePricingComparision';
import WhyChooseUs from '../components/WhyChooseUs';
import VPSFeatures from '../components/VPSFeatures';
import { BiSolidCloudUpload } from 'react-icons/bi';



export default function RootReseller() {
  const questions = [
    { 
      question: "What is root reseller hosting and how does Host Welcome support it?", 
      answer: "Root reseller hosting allows you to have full control over your reseller hosting environment, including the ability to create, manage, and customize reseller accounts. Host Welcome provides root reseller hosting with WHM (Web Host Manager) access, giving you full administrative privileges to manage and resell hosting accounts under your own brand."
    },
    { 
      question: "What are the key benefits of root reseller hosting with Host Welcome?", 
      answer: "With Host Welcome’s root reseller hosting, you gain complete control over your reseller hosting environment. You can create and manage multiple reseller accounts, allocate resources, set pricing, and provide customized services to your clients. This level of control allows you to run your own hosting business with flexibility and scalability."
    },
    { 
      question: "How can root reseller hosting help scale my hosting business?", 
      answer: "Root reseller hosting is the ideal solution for businesses looking to scale. With root access, you can manage multiple clients, allocate resources as needed, and customize services based on your customers' demands. The flexibility of root reseller hosting ensures that your business can grow without limitations."
    },
    { 
      question: "What root reseller hosting plans does Host Welcome offer?", 
      answer: "Host Welcome offers a range of root reseller hosting plans designed to provide the highest level of control and flexibility. Our plans come with WHM access, allowing you to manage unlimited accounts and resources. Visit our [Root Reseller Hosting Plans](/root-reseller-hosting) page to find the plan that fits your needs."
    },
    { 
      question: "How does Host Welcome ensure the security of root reseller hosting accounts?", 
      answer: "Security is a top priority at Host Welcome. With root reseller hosting, you have full control over security settings for your reseller accounts. We provide advanced security features such as SSL certificates, firewalls, DDoS protection, and regular backups to keep your accounts and client data safe."
    },
    { 
      question: "Is root reseller hosting suitable for large-scale businesses?", 
      answer: "Yes, root reseller hosting is ideal for large-scale businesses and web hosting companies. The ability to manage multiple reseller accounts and offer customized hosting plans makes it perfect for growing businesses that want to offer hosting as a service."
    },
    { 
      question: "Can I offer custom plans with Host Welcome’s root reseller hosting?", 
      answer: "Yes, with root reseller hosting from Host Welcome, you can create and offer customized hosting plans. You have the flexibility to set disk space, bandwidth, and other resources for each reseller account, allowing you to tailor services to meet the specific needs of your clients."
    },
    { 
      question: "What kind of customer support does Host Welcome offer for root reseller hosting?", 
      answer: "Host Welcome provides 24/7 support for root reseller hosting clients. Our expert team is available to assist you with any technical issues, account management questions, or general inquiries. Whether you need help setting up your root reseller account or troubleshooting, our team is ready to provide you with the support you need."
    },
    { 
      question: "Can Host Welcome handle a large number of root reseller hosting accounts?", 
      answer: "Yes, Host Welcome’s root reseller hosting plans are designed to handle a large number of accounts. With powerful infrastructure and flexible resource management tools, you can easily scale your business and manage numerous reseller accounts without compromising on performance."
    },
    { 
      question: "How can I migrate my root reseller hosting account to Host Welcome?", 
      answer: "Migrating to Host Welcome’s root reseller hosting is simple and hassle-free. Our migration team provides free migration services to help you move your accounts from other hosting providers. Visit our [Migration page](/migration) to learn more about the process."
    },
    { 
      question: "Does Host Welcome offer a money-back guarantee for root reseller hosting?", 
      answer: "Yes, Host Welcome offers a 30-day money-back guarantee on all root reseller hosting plans. If you're not satisfied with our services, you can request a full refund within the first 30 days. For more details, visit our [Money-Back Guarantee page](/money-back-guarantee)."
    }
  ];
  
  const HomePlans = [
    {
      name: "BASIC ROOT RESELLER",
      price: "60",
      cents: "99",
      color: "slate",
      features: [
        { icon: <FaServer />, text: "Unlimited Reseller Accounts" },
        { icon: <FaRegHardDrive />, text: "100 GB SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL Certificate" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel & WHM Access" },
        { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
        { icon: <LuPanelTop />, text: "Free Daily Backups" },
        { icon: <FaCogs />, text: "Full Root Access" },
      ],
    },
    {
      name: "STANDARD ROOT RESELLER",
      price: "120",
      cents: "99",
      color: "sky",
      bestSeller: true,
      features: [
        { icon: <FaServer />, text: "Unlimited Reseller Accounts" },
        { icon: <FaRegHardDrive />, text: "250 GB SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL Certificate" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel & WHM Access" },
        { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
        { icon: <LuPanelTop />, text: "Free Daily Backups + Free CDN" },
        { icon: <FaCogs />, text: "Full Root Access + Server Management" },
        { icon: <FaShieldAlt />, text: "DDoS Protection" },
      ],
    },
    {
      name: "BUSINESS ROOT RESELLER",
      price: "200",
      cents: "99",
      color: "green",
      features: [
        { icon: <FaServer />, text: "Unlimited Reseller Accounts" },
        { icon: <FaRegHardDrive />, text: "500 GB SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL & SiteLock Security" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel & WHM Access" },
        { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
        { icon: <LuPanelTop />, text: "Free Daily Backups + Free CDN" },
        { icon: <FaCogs />, text: "Full Root Access + Server Management + Optimization" },
        { icon: <MdSecurity />, text: "Malware Scan & Removal" },
      ],
    },
    {
      name: "PRO ROOT RESELLER",
      price: "350",
      cents: "99",
      color: "orange",
      features: [
        { icon: <FaServer />, text: "Unlimited Reseller Accounts" },
        { icon: <FaRegHardDrive />, text: "1 TB SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL, SiteLock & DDoS Protection" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel & WHM Access" },
        { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
        { icon: <LuPanelTop />, text: "Free Daily Backups + Free CDN + Priority Support" },
        { icon: <FaCogs />, text: "Full Root Access + Full Server Management" },
        { icon: <FaShieldAlt />, text: "Advanced DDoS Protection" },
        { icon: <MdSecurity />, text: "Malware Scan & Removal" },
      ],
    },
  ];
  
 const hostingGrid = [
  {
    id: 1,
    title: "Root Reseller Hosting",
    badge: "NVMe SSD",
    icon: <SiCpanel />,
    text: "High-performance hosting package for websites with high traffic. Optimized for WordPress, Magento, and other CMS.",
    link:"/rootreseller-hosting"
  },
     {
       id: 2,
       title: "Reseller Hosting",
       badge: "NVMe SSD",
       icon: <FaGears />,
       text: "High-performance hosting package for websites with high traffic. Optimized for WordPress, Magento, and other CMS.",
       link:"/reseller-hosting"
     },

 ];
 const features = [
  {
    id: 1,
    title: "Full Root Access",
    description:
      "With Root Reseller Hosting, you get full root access to your server. This allows you to have complete control over your environment, including the ability to install custom software, configure security settings, and manage your hosting clients at the root level.",
    icon: <FaServer />,
  },
  {
    id: 2,
    title: "Unlimited Reseller Accounts",
    description:
      "You can create and manage unlimited reseller accounts. Assign custom resources, allocate bandwidth, disk space, and set up independent WHM accounts for your clients without any restrictions.",
    icon: <FaRegHandshake />,
  },
  {
    id: 3,
    title: "Complete Customization & Branding",
    description:
      "Customize your server environment and control the look and feel of your hosting plans. Add custom branding for client-facing interfaces like cPanel, WHM, and your hosting website.",
    icon: <FaCogs />,
  },
  {
    id: 4,
    title: "Free SSL & Security Features",
    description:
      "Ensure your reseller hosting accounts are secure with free SSL certificates, DDoS protection, and advanced firewall configurations. Safeguard your clients’ websites and enhance your SEO rankings.",
    icon: <FaShieldAlt />,
  },
  {
    id: 5,
    title: "Scalable Resources",
    description:
      "As your hosting business grows, so does your need for resources. Easily scale up server resources such as RAM, CPU, and storage to meet increasing client demands, ensuring zero downtime.",
    icon: <FaRocket />,
  },
  {
    id: 6,
    title: "Dedicated IP & Private Name Servers",
    description:
      "Offer your clients the option of dedicated IP addresses and private name servers. This enhances the credibility of your hosting services and provides better SEO benefits for your clients.",
    icon: <FaServer />,
  },
  {
    id: 7,
    title: "Advanced Resource Management",
    description:
      "Take full control of resource allocation using tools like CloudLinux OS, enabling you to set resource limits per reseller account to prevent abuse and ensure fair usage.",
    icon: <MdAttachMoney />,
  },
  {
    id: 8,
    title: "24/7 Dedicated Support",
    description:
      "You will have access to a dedicated support team for all root reseller hosting issues. Whether it’s server management, software installation, or troubleshooting, we provide expert help 24/7.",
    icon: <MdSupportAgent />,
  },
  {
    id: 9,
    title: "Automated Backups & Restore",
    description:
      "Root reseller hosting includes automated backups and one-click restores, ensuring your clients’ websites are always protected against data loss, accidents, or malicious attacks.",
    icon: <BiSolidCloudUpload />,
  },
  {
    id: 10,
    title: "High Profit Margins",
    description:
      "With root reseller hosting, you can set your own prices for the hosting packages. Control pricing tiers for your clients while earning significant profits as your business scales.",
    icon: <MdAttachMoney />,
  },
];
  return (
<>
<Helmet>
        <title>Root Reseller Hosting in Pakistan - WHM & cPanel | Host Welcome</title>
        <meta 
          name="description" 
          content="Start your hosting business with our root reseller hosting in Pakistan. Get full WHM access, white-label solutions, SSD storage, and 24/7 technical support. Perfect for entrepreneurs and agencies looking to sell hosting services." 
        />
      </Helmet>
<div className='rootresllerhostinghead'></div>
<header>
         <div className='shared-head-banner d-flex
         '>
             <span><SiCpanel /><p>Root Reller Hosting </p></span>
         </div>
         </header>
         <div className='number-bar'>
         <p className='pe-4'>Have any Question?</p>
         <p className='pe-4'>We have Precise answer. </p>
         <MdOutlineLocalPhone />
         
         <p>+92 3242163697</p>
         </div>
         
         
         <section><HostingGrid hostingGrid={hostingGrid}/></section>
         <section className='resellerhostingplans'>
    <h2>Plan and Price</h2>
    <p>Here's the comparison chart to pick the correct hosting plan based on your needs and budget.</p>
  <HomePricingComparision HomePlans={HomePlans}/>
</section>
<VPSFeatures title="Why Choose Root Reseller Hosting" features={features}/>
<Topfeature/>

<DemoBox/>
<WhyChooseUs/>
<Topfeature/>
<CustomerReviews/>
<QASection questions={questions}/>
         </>


  )
}
