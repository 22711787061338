import React from "react";
import { Link } from "react-router-dom"; // Import Link
import { FaGlobe, FaBusinessTime } from "react-icons/fa";

const SharedHostingFeature = ({ hostingFeatures }) => {
  return (
    <div className="shared-hosting-container">
      <h2 className="shared-hosting-heading">Basic Web Hosting</h2>
      <div className="shared-hosting-grid">
        {hostingFeatures.map((feature, index) => (
          <div key={index} className="hosting-feature-box">
            <div className="icon-circle">{feature.icon}</div>
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
            <Link to={feature.link} className="feature-button">
              {feature.button}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SharedHostingFeature;
