import React from "react";



const Domaintlds = () => {
  const domains = [
    { extension: "com", price: "$13/yr" },

    { extension: "org", price: "$15/yr" },
    { extension: "pk", price: "$10/yr" },

 
  ];

  return (
    <div className="domains-container container">
      <h2>Get Yourself the Right Top Level Domain Name</h2>
  
      <div className="domains-grid">
        {domains.map((domain) => (
          <div key={domain.extension} className="domain-item">
            <h3>{domain.extension}</h3>
            <p className="d-flex">Get in just <span>{domain.price}</span></p>
            <button>Search Now</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Domaintlds