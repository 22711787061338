import React from 'react'
import { FaBriefcase, FaRegHardDrive, FaUsersGear } from "react-icons/fa6";
import { MdAttachMoney, MdOutlineLocalPhone, MdSecurity, MdSpeed, MdSupportAgent } from "react-icons/md";
import HostingGrid from '../components/HostingCard';

import { FaChartLine, FaCogs, FaGlobeAmericas, FaLock, FaRocket, FaServer, FaSync, FaUserTie } from "react-icons/fa";



import { SiMinutemailer } from "react-icons/si";

import { LuPanelTop } from "react-icons/lu";
import { MdEmail } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import Topfeature from '../components/Topfeature';
import QASection from '../components/Questionmaries';
import DemoBox from '../components/DemonBox';

import CustomerReviews from '../components/CustomerReviews';
import { Helmet } from 'react-helmet';
import HomePricingComparision from '../components/HomePricingComparision';
import WhyChooseUs from '../components/WhyChooseUs';
import { BiSolidCloudUpload } from 'react-icons/bi';
import VPSFeatures from '../components/VPSFeatures';
import { IoSpeedometerSharp } from 'react-icons/io5';



export default function ResellerHosting() {
  const questions = [
    { 
      question: "What is reseller hosting and how does Host Welcome support it?", 
      answer: "Reseller hosting allows you to buy hosting resources in bulk and resell them to your customers. Host Welcome provides reliable reseller hosting solutions that come with white-label branding, full control over hosting plans, and advanced support to help you build your hosting business with ease."
    },
    { 
      question: "What are the key benefits of reseller hosting with Host Welcome?", 
      answer: "With Host Welcome’s reseller hosting, you can offer customized hosting plans to your clients, maintain complete control over pricing and resources, and earn recurring income. Our reseller hosting is built for performance, scalability, and comes with 24/7 support to ensure your business runs smoothly."
    },
    { 
      question: "How can reseller hosting help grow my business?", 
      answer: "Reseller hosting with Host Welcome gives you the opportunity to offer hosting services under your own brand, adding an additional revenue stream to your business. With our reliable infrastructure, you'll be able to scale your hosting business and attract more customers, providing them with the hosting solutions they need."
    },
    { 
      question: "What reseller hosting plans does Host Welcome offer?", 
      answer: "Host Welcome offers a variety of reseller hosting plans to suit different business needs. These plans come with features like white-label branding, cPanel/WHM access, unlimited email accounts, and more. You can explore all our [Reseller Hosting Plans](/reseller-hosting) to find the best option for your business."
    },
    { 
      question: "How does Host Welcome ensure the security of reseller hosting websites?", 
      answer: "Host Welcome takes security seriously and provides reseller hosting clients with essential security features such as SSL certificates, DDoS protection, automated backups, and malware scanning. Our goal is to ensure that your reseller business and your clients' websites remain safe and secure."
    },
    { 
      question: "Is Host Welcome’s reseller hosting suitable for small businesses?", 
      answer: "Yes, Host Welcome's reseller hosting is ideal for small businesses that want to offer web hosting services to their customers. With our flexible plans and easy-to-use control panel, small businesses can efficiently manage multiple hosting accounts and deliver reliable services to clients."
    },
    { 
      question: "Can I offer custom hosting plans with Host Welcome’s reseller hosting?", 
      answer: "Yes, Host Welcome allows you to create and offer custom hosting plans with your reseller account. You have full control over the resources (disk space, bandwidth, etc.) and can tailor plans to meet the specific needs of your customers, providing a personalized experience."
    },
    { 
      question: "What kind of customer support does Host Welcome provide for reseller hosting?", 
      answer: "Host Welcome provides 24/7 technical support for reseller hosting clients. You can rely on our dedicated team to assist you with any issues you may encounter while managing your hosting business. Our support team is available via live chat, email, and phone for quick and efficient resolutions."
    },
    { 
      question: "Can Host Welcome handle a large number of reseller hosting clients?", 
      answer: "Yes, Host Welcome’s reseller hosting plans are scalable and designed to handle a large number of clients. With powerful servers, advanced tools, and flexible resource allocation, you can grow your reseller business and manage many customers without compromising on performance."
    },
    { 
      question: "How can I migrate my reseller hosting account to Host Welcome?", 
      answer: "Migrating your reseller hosting account to Host Welcome is easy. We offer free migration services to help you transfer your existing hosting accounts to our platform. Visit our [Migration page](/migration) for detailed instructions on how to get started."
    },
    { 
      question: "Does Host Welcome offer a money-back guarantee for reseller hosting?", 
      answer: "Yes, Host Welcome offers a 30-day money-back guarantee on all our reseller hosting plans. If you’re not satisfied with our services, you can request a full refund within the first 30 days. Learn more on our [Money-Back Guarantee page](/money-back-guarantee)."
    }
  ];
  
  const HomePlans = [
    {
      name: "BASIC RESELLER",
      price: "15",
      cents: "99",
      color: "slate",
      features: [
        { icon: <FaServer />, text: "5 Reseller Accounts" },
        { icon: <FaRegHardDrive />, text: "50 GB SSD Storage" },
        { icon: <FaDatabase />, text: "25 MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL Certificate" },
        { icon: <MdEmail />, text: "50 Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel & WHM Access" },
        { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
      ],
    },
    {
      name: "STANDARD RESELLER",
      price: "30",
      cents: "99",
      color: "sky",
      bestSeller: true,
      features: [
        { icon: <FaServer />, text: "Unlimited Reseller Accounts" },
        { icon: <FaRegHardDrive />, text: "100 GB SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL Certificate" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel & WHM Access" },
        { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
        { icon: <LuPanelTop />, text: "Free Daily Backups" },
      ],
    },
    {
      name: "BUSINESS RESELLER",
      price: "50",
      cents: "99",
      color: "green",
      features: [
        { icon: <FaServer />, text: "Unlimited Reseller Accounts" },
        { icon: <FaRegHardDrive />, text: "200 GB SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL & SiteLock Security" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel & WHM Access" },
        { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
        { icon: <LuPanelTop />, text: "Free Daily Backups + Free CDN" },
      ],
    },
    {
      name: "PRO RESELLER",
      price: "80",
      cents: "99",
      color: "orange",
      features: [
        { icon: <FaServer />, text: "Unlimited Reseller Accounts" },
        { icon: <FaRegHardDrive />, text: "500 GB SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL, SiteLock & DDoS Protection" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel & WHM Access" },
        { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
        { icon: <LuPanelTop />, text: "Free Daily Backups + Free CDN + Priority Support" },
        { icon: <MdSecurity />, text: "Malware Scan & Removal" },
      ],
    },
  ];
  
    const hostingGrid = [
      {
        id: 1,
        title: "Reseller Hosting",
        badge: "NVMe SSD",
        icon: <FaCogs/>, 
        text: "Start your own hosting business with our Reseller Hosting. Complete control with WHM and cPanel.",
        link: "/reseller-hosting",
      },
      {
        id: 2,
        title: "Root Reseller Hosting",
        badge: "NVMe SSD",
        icon: <FaBriefcase />, 
        text: "High-performance hosting package for websites with high traffic. Optimized for WordPress, Magento, and other CMS platforms.",
        link: "/rootreseller-hosting",
      },
    

    ];

    const features = [
      {
        id: 1,
        title: "White-Label Reseller Hosting",
        description:
          "Brand your own web hosting business with complete white-label solutions. Sell hosting under your own name without revealing our branding.",
        icon: <FaUserTie />,
      },
      {
        id: 2,
        title: "Free WHM & cPanel",
        description:
          "Get a powerful WHM (Web Host Manager) and cPanel accounts for easy client management, domain control, and automated hosting services.",
        icon: <FaCogs />,
      },
      {
        id: 3,
        title: "100% SSD Storage & High-Speed Performance",
        description:
          "Our reseller hosting is powered by SSD storage, LiteSpeed caching, and CloudLinux OS, ensuring fast website performance for your clients.",
        icon: <MdSpeed />,
      },
      {
        id: 4,
        title: "Free SSL Certificates for All Domains",
        description:
          "Every account includes free SSL certificates, providing secure HTTPS connections and improving SEO rankings for hosted websites.",
        icon: <FaLock />,
      },
      {
        id: 5,
        title: "SEO-Friendly Hosting Environment",
        description:
          "Fast load times, secure servers, and uptime guarantees help boost SEO rankings for all websites hosted on our reseller plans.",
        icon: <FaChartLine />,
      },
      {
        id: 6,
        title: "Automated Backups & Instant Restores",
        description:
          "Protect client websites with daily automated backups. Restore data instantly in case of any issues.",
        icon: <FaSync />,
      },
      {
        id: 7,
        title: "One-Click App Installer (Softaculous)",
        description:
          "Install over 400+ apps, including WordPress, Joomla, and Magento, with just one click using Softaculous.",
        icon: <BiSolidCloudUpload />,
      },
      {
        id: 8,
        title: "24/7 Reseller Hosting Support",
        description:
          "Our hosting experts are available 24/7 to help you with technical issues, server management, and reseller-related queries.",
        icon: <MdSupportAgent />,
      },
      {
        id: 9,
        title: "High Profit Margins & Custom Pricing",
        description:
          "Set your own prices, create custom hosting plans, and earn high profits by reselling hosting services to clients.",
        icon: <MdAttachMoney />,
      },
      {
        id: 10,
        title: "Scalable & Growth-Oriented Plans",
        description:
          "Upgrade your reseller hosting plan as your business grows. No downtime, no migration hassle!",
        icon: <FaRocket />,
      },
    ];
  return (
<>
<Helmet>
        <title>Reseller Hosting in Pakistan - Start Your Hosting Business | Host Welcome</title>
        <meta 
          name="description" 
          content="Start your own web hosting business with our reseller hosting plans in Pakistan. Get cPanel/WHM, white-label branding, and 24/7 support. Ideal for web developers and agencies." 
        />
      </Helmet>
<div className='resllerhostinghead'></div>
<header>
         <div className='shared-head-banner d-flex
         '>
             <span><FaUsersGear /><p>Reseller Hosting </p></span>
         </div>
         </header>
         <div className='number-bar'>
         <p className='pe-4'>Have any Question?</p>
         <p className='pe-4'>We have Precise answer. </p>
         <MdOutlineLocalPhone />
         
         <p>+92 3242163697</p>
         </div>
         
         
         <section><HostingGrid hostingGrid={hostingGrid}/></section>
         <section className='resellerhostingplans'>
    <h2>Plan and Price</h2>
    <p>Here's the comparison chart to pick the correct hosting plan based on your needs and budget.</p>
    <HomePricingComparision HomePlans={HomePlans}/>
</section>
<VPSFeatures features={features} title="Why Choose Reseller Hosting"/>
<Topfeature/>

<DemoBox/>
<WhyChooseUs/>
<CustomerReviews/>
<QASection questions={questions}/>
         </>


  )
}
