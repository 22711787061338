import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlus,
  faMinus, 
  faBars, 
  faTimes,
  faServer, 
  faCloud, 
  faRocket, 
  faSearch, 
  faExchangeAlt,
  faShieldAlt,
  faWallet,
  faEnvelope,
  faChartLine,
  faCog,
  faGlobe,
  faDatabase
} from '@fortawesome/free-solid-svg-icons';
import { BsGlobeAsiaAustralia } from 'react-icons/bs';
import { SiCpanel } from 'react-icons/si';
import { FaWordpress, FaBriefcase, FaWindows, FaGlobe } from 'react-icons/fa';
import { AiOutlineLinux } from 'react-icons/ai';
import { HiServer } from 'react-icons/hi';
import { FaUsersGear } from 'react-icons/fa6';
import { FaShieldAlt } from "react-icons/fa";
import { LuServerCog } from "react-icons/lu";

const Navbar2 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const menuItems = [
    {
      title: 'Hosting',
      icon: faCloud,
      subItems: [
        { icon: <SiCpanel />, text: 'Basic Web Hosting', link: '/basic-web-hosting' },
        { icon: <FaBriefcase />, text: 'Business Hosting', link: '/business-hosting' },
        { icon: <FaWordpress />, text: 'Wordpress Hosting', link: '/wordpress-hosting' },
        { icon: <FaUsersGear />, text: 'Reseller Hosting', link: '/reseller-hosting' },
        { icon: <SiCpanel />, text: 'Root Reseller Hosting', link: '/rootreseller-hosting' },
      ]
    },
    {
      title: 'Domains',
      icon: faGlobe,
      subItems: [
        { icon: <FaGlobe />, text: 'Domain Registration', link: '/domain-registration' },
        { icon: <BsGlobeAsiaAustralia />, text: 'Pk Domain ', link: '/pk-domain-registration' },
      ]
    },
    {
      title: 'Server',
      icon: faServer,
      subItems: [
        { icon: <FaWindows />, text: 'Windows Server', link: '/windows-vps' },
        { icon: <AiOutlineLinux />, text: 'Linux VPS', link: '/linux-vps' },
        { icon: <HiServer />, text: 'Dedicated Server', link: '/dedicated-server' }
      ]
    },
    {
      title: 'Company',
      icon: faWallet,
      subItems: [
        { text: 'About Us', link: '/aboutus' },
        { text: 'Contact', link: '/contact' },
        { text: 'Terms & Policies', link: '/terms' }
      ]
    },
    {
      title: 'Services',
      icon: faShieldAlt,
      subItems: [
        { icon: <FaShieldAlt />, text: 'SSL Certificate', link: '/ssl-certificate' },
        { icon: <LuServerCog /> ,text: 'Server Management', link: '/server-management' },
      ]
    }
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setActiveDropdown(null); // Reset dropdown on menu toggle
  };

  const handleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 1255;  // Updated to trigger hamburger menu at 1255px
      setIsMobile(mobile);
      if (!mobile) {
        setIsMenuOpen(false);
        setActiveDropdown(null);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <nav className="navbar sticky-navbar">
      <a href="/" className="logo">HostWelcome</a>

      <div className={`menu ${isMenuOpen ? 'active' : ''}`}>
        {menuItems.map((item, index) => (
          <div 
            key={index}
            className={`menu-item ${activeDropdown === index ? 'active' : ''}`}
            onMouseEnter={() => !isMobile && setActiveDropdown(index)}
            onMouseLeave={() => !isMobile && setActiveDropdown(null)}
          >
            <div 
              className="menu-link" 
              onClick={() => isMobile && handleDropdown(index)}
            >
              <FontAwesomeIcon icon={item.icon} />
              {item.title}
              {item.subItems && isMobile && (
                <FontAwesomeIcon 
                  icon={activeDropdown === index ? faMinus : faPlus} 
                  className="dropdown-toggle-icon" 
                />
              )}
            </div>
            
            {item.subItems && (
              <div className={`dropdown ${activeDropdown === index ? 'active' : ''}`}>
                {item.subItems.map((subItem, subIndex) => (
                  <a href={subItem.link} key={subIndex} className="dropdown-item">
                    {subItem.icon && subItem.icon}
                    {subItem.text}
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
        
        <div className="mobile-auth-buttons">
        <a href="https://hostwelcome.com/clients/clientarea.php" className="login-btn">
    Login
</a>
<a href="https://hostwelcome.com/clients/register.php" className="register-btn">
    Register
</a>

        </div>
      </div>

      <div className="desktop-auth-buttons">
      <a href="https://hostwelcome.com/clients/clientarea.php" className="login-btn">
    Login
</a>
<a href="https://hostwelcome.com/clients/register.php" className="register-btn">
    Register
</a>

      
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
      </div>

      <style jsx>{`
        .navbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 5%;
          background: #fff;
          box-shadow: 0 2px 10px rgba(0,0,0,0.1);
          position: relative;
        }

        .sticky-navbar {
          position: sticky;
          top: 0;
          z-index: 100;
        }

        .logo {
          color: #008c0d;
          font-size: 1.5rem;
          font-weight: 600;
          text-decoration: none;
        }

        .menu {
          display: flex;
          gap: 2rem;
          list-style: none;
          transition: all 0.3s ease;
        }

        .menu-item {
          position: relative;
          cursor: pointer;
        }

        .menu-link {
          color: #333;
          padding: 0.5rem 0;
          display: flex;
          align-items: center;
          gap: 0.8rem;
          transition: all 0.3s ease;
        }

        .menu-link:hover {
          color: #008c0d;
        }

        .dropdown-toggle-icon {
          transition: transform 0.3s ease;
          font-size: 0.8rem;
        }

        .dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          background: #fff;
          box-shadow: 0 4px 12px rgba(0,0,0,0.1);
          border-radius: 8px;
          padding: 0;
          min-width: 200px;
          max-height: 0;
          overflow: hidden;
          transition: all 0.3s ease;
          z-index: 1000;
        }

        .dropdown.active {
          max-height: 500px;
          padding: 0.5rem 0;
          animation: slideDown 0.3s ease;
        }

        .dropdown-item {
          padding: 0.8rem 1.5rem;
          display: flex;
          align-items: center;
          gap: 1rem;
          text-decoration: none;
          color: #333;
          transition: all 0.3s ease;
        }

        .dropdown-item:hover {
          background: #f5f5f5;
          color: #008c0d;
        }

        .desktop-auth-buttons {
          display: flex;
          gap: 1rem;
        }

        .mobile-auth-buttons {
          display: none;
        }

        a {
          padding: 0.6rem 1.8rem;
          border-radius: 6px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .login-btn {
          background: transparent;
          border: 2px solid #008c0d;
          color: #008c0d;
        }

        .login-btn:hover {
          background: #008c0d;
          color: white;
        }

        .register-btn {
          background: #008c0d;
          border: 2px solid #008c0d;
          color: white;
        }

        .register-btn:hover {
          background: #00720b;
          border-color: #00720b;
        }

        .hamburger {
          display: none;
          cursor: pointer;
          font-size: 1.5rem;
          color: #008c0d;
        }

        @media (max-width: 1255px) {
          .menu {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background: white;
            flex-direction: column;
            padding: 0 2rem;
            gap: 0;
            max-height: 0;
            overflow: hidden;
            transition: all 0.3s ease;
          }

          .menu.active {
            max-height: 100vh;
            padding: 2rem;
            box-shadow: 0 4px 20px rgba(0,0,0,0.1);
          }

          .menu-item {
            width: 100%;
            border-bottom: 1px solid #eee;
          }

          .menu-link {
            padding: 1rem 0;
            justify-content: space-between;
          }

          .dropdown {
            position: relative;
            top: auto;
            left: auto;
            box-shadow: none;
            background: #f8f8f8;
            margin: 0 -1rem;
            border-radius: 0;
            transition: all 0.3s ease;
          }

          .dropdown.active {
            max-height: 500px;
          }

          .dropdown-item {
            padding: 1rem 2rem;
          }

          .hamburger {
            display: block;
          }

          .desktop-auth-buttons {
            display: none;
          }

          .mobile-auth-buttons {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }
        }
      `}</style>
    </nav>
  );
};

export default Navbar2;
