import React, { useEffect } from 'react'

import QASection from '../components/Questionmaries';
import PricingTable from '../components/PricingTable';
import LocationsComponent from '../components/LocationComponent';
import { IoMdContacts } from 'react-icons/io';
import { IoBriefcase } from 'react-icons/io5';
import { Helmet } from 'react-helmet';
import WhyChooseUs from '../components/WhyChooseUs';
import Topfeature from '../components/Topfeature';

import { FaCogs, FaLinux, FaLock, FaNetworkWired, FaRocket, FaServer, FaWindows } from 'react-icons/fa';

import { BiSolidCloudUpload } from 'react-icons/bi';
import { MdSecurity, MdSupportAgent } from 'react-icons/md';
import VPSFeatures from '../components/VPSFeatures';

export default function DedicatedServer() {

  const questions = [
    { 
      question: "What is dedicated server hosting and how does Host Welcome support it?", 
      answer: "Dedicated server hosting provides you with a physical server exclusively for your website or application. Host Welcome offers high-performance dedicated servers with full root access, allowing you to customize your hosting environment and scale your resources based on your business needs."
    },
    { 
      question: "What are the key benefits of dedicated server hosting with Host Welcome?", 
      answer: "With Host Welcome’s dedicated server hosting, you get complete control over your server, enhanced performance, and maximum security. Dedicated servers provide guaranteed resources, faster processing, and more stability compared to shared hosting. This makes it ideal for businesses with high-traffic websites, resource-heavy applications, or those requiring custom configurations."
    },
    { 
      question: "How does Host Welcome’s dedicated server hosting differ from shared hosting?", 
      answer: "Unlike shared hosting, where resources are shared with other users, dedicated server hosting at Host Welcome provides an entire server dedicated solely to you. This ensures faster performance, enhanced security, and more control over server configurations, making it perfect for high-traffic websites or complex applications."
    },
    { 
      question: "What dedicated server hosting plans does Host Welcome offer?", 
      answer: "Host Welcome offers a range of dedicated server hosting plans, each designed to meet different business needs. Our plans come with varying CPU power, RAM, storage, and bandwidth to accommodate websites and applications of all sizes. Visit our [Dedicated Server Hosting Plans](/dedicated-server-hosting) to find the right plan for your business."
    },
    { 
      question: "Is dedicated server hosting suitable for high-traffic websites?", 
      answer: "Yes, dedicated server hosting is ideal for high-traffic websites that require large amounts of resources, such as eCommerce stores, media websites, or enterprise applications. Host Welcome’s dedicated servers ensure optimal performance and uptime, even during peak traffic periods."
    },
    { 
      question: "How does Host Welcome ensure the security of dedicated servers?", 
      answer: "Host Welcome takes security seriously, providing advanced features such as firewalls, DDoS protection, and regular security patches. Additionally, with dedicated servers, you have full control over your server’s security settings, allowing you to implement your own security measures based on your needs."
    },
    { 
      question: "Can I customize my dedicated server hosting plan with Host Welcome?", 
      answer: "Yes, Host Welcome provides highly customizable dedicated server hosting plans. You can choose the specifications that best suit your business needs, such as CPU, RAM, storage, and bandwidth. You also have the flexibility to install custom software and configure the server environment as needed."
    },
    { 
      question: "What kind of customer support does Host Welcome provide for dedicated server hosting?", 
      answer: "Host Welcome offers 24/7 customer support for all dedicated server hosting clients. Our team of experts is available around the clock via live chat, email, or phone to assist with server setup, performance optimization, troubleshooting, or any technical issue you may encounter."
    },
    { 
      question: "What operating systems are available for dedicated server hosting with Host Welcome?", 
      answer: "Host Welcome supports both Linux and Windows operating systems for dedicated server hosting. You can choose the OS that best fits your needs, whether you’re running Linux-based applications or require a Windows environment for specific software."
    },
    { 
      question: "Does Host Welcome offer a money-back guarantee for dedicated server hosting?", 
      answer: "Yes, Host Welcome offers a 30-day money-back guarantee on all our dedicated server hosting plans. If you’re not satisfied with our service, you can request a full refund within the first 30 days. For more details, visit our [Money-Back Guarantee page](/money-back-guarantee)."
    },
    { 
      question: "Can I upgrade my dedicated server hosting plan with Host Welcome?", 
      answer: "Yes, Host Welcome allows you to easily upgrade your dedicated server hosting plan as your business grows. You can scale your resources, including CPU, RAM, storage, and bandwidth, to meet your evolving needs. Our team is available to help with the upgrade process."
    }
  ];
  
  const features = [
    {
      id: 1,
      title: "Full Server Control",
      description:
        "With Dedicated Server Hosting, you have complete control over your server. You can install any software, configure your server settings, and manage your hosting environment without any limitations. Enjoy root access for maximum flexibility and control.",
      icon: <FaServer />,
    },
    {
      id: 2,
      title: "High Performance & Speed",
      description:
        "Our dedicated servers are equipped with the latest hardware, offering unparalleled performance. With high-speed SSD storage, dedicated bandwidth, and powerful CPUs, your websites and applications will run with maximum efficiency.",
      icon: <FaRocket />,
    },
    {
      id: 3,
      title: "Enhanced Security Features",
      description:
        "Dedicated servers come with advanced security features such as **DDoS protection**, **firewall management**, and **data encryption**. You have full control over security settings to protect your server from unauthorized access and cyber threats.",
      icon: <FaLock />,
    },
    {
      id: 4,
      title: "Scalable Resources",
      description:
        "Easily upgrade or scale your server's resources, including RAM, CPU, and storage, based on your growing business needs. Whether you need more processing power or additional disk space, scaling is simple and efficient.",
      icon: <FaCogs />,
    },
    {
      id: 5,
      title: "100% Uptime Guarantee",
      description:
        "Enjoy a **100% uptime guarantee** with our dedicated server hosting. We offer reliable performance and redundant infrastructure to ensure that your website and applications are always available to your clients, anywhere in the world.",
      icon: <FaNetworkWired />,
    },
    {
      id: 6,
      title: "Customizable & Dedicated IPs",
      description:
        "Dedicated servers come with dedicated IP addresses. You can configure your server with as many IPs as needed for specific projects or clients. This enhances SEO by providing more control over search engine indexing and server configurations.",
      icon: <FaServer />,
    },
    {
      id: 7,
      title: "24/7 Dedicated Support",
      description:
        "We offer 24/7 expert support to help you manage and troubleshoot your dedicated server. Our dedicated support team is available around the clock to ensure your server is performing optimally.",
      icon: <MdSupportAgent />,
    },
    {
      id: 8,
      title: "Fully Managed Hosting Options",
      description:
        "Opt for a fully managed dedicated server if you prefer to focus on your business while we handle your server management. We take care of updates, maintenance, and system security to ensure the smooth operation of your hosting environment.",
      icon: <MdSecurity />,
    },
    {
      id: 9,
      title: "Automatic Backups & Disaster Recovery",
      description:
        "Your data is safe with our automated backup solutions. We ensure regular backups of your server data with fast disaster recovery options, so you can quickly restore your website or application in case of any issues.",
      icon: <BiSolidCloudUpload />,
    },
    {
      id: 10,
      title: "Fully Optimized for SEO",
      description:
        "Our dedicated servers are optimized for SEO. You can configure your server for fast loading times, secure HTTPS, and other critical SEO features that can help improve your website's ranking on search engines.",
      icon: <FaRocket />,
    },
  ];

 

  return (
    
   <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Dedicated Servers | Host Welcome</title>
        <meta name="description" content="Get powerful and reliable dedicated servers for maximum performance, security, and control." />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* Open Graph and Twitter Card meta tags for better visibility on social media */}
        <meta property="og:title" content="Dedicated Servers | Host Welcome" />
        <meta property="og:description" content="Powerful and customizable dedicated servers for your business needs. Full control, maximum performance, and enhanced security." />
        <meta property="og:image" content="URL_to_image_for_og_image" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Dedicated Servers | Host Welcome" />
        <meta name="twitter:description" content="Get powerful dedicated servers for unmatched performance and security." />
        <meta name="twitter:image" content="URL_to_image_for_twitter_card" />
      </Helmet>
       <div className='business-hosting-banner
       '>
           <header>
           <div className='shared-head-banner d-flex
           '>
               <span><IoBriefcase /><p>
                Dedicated Servers </p></span>
           </div>
           </header>
           <div className='number-bar'>
           <p className='pe-4'>Have any Question?</p>
           <p className='pe-4'>We have Precise answer. </p>
           <IoMdContacts />
           
           <p>+92 3242163697</p>
           </div>
       </div>



      <PricingTable/>

      <LocationsComponent/>
      <VPSFeatures title="Why Choose Dedicated Server" features={features}/>
      <WhyChooseUs/>
      <Topfeature/>
      <QASection questions={questions}/>
      </>
  )
}
