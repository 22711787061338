import React, { useState } from 'react';

const DomainSearch = () => {
  const [domainName, setDomainName] = useState('');

  const handleSearch = () => {
    if (domainName) {
      // Redirect to the registration URL with the entered domain
      window.location.href = `https://hostwelcome.com/clients/cart.php?a=add&domain=register&query=${domainName}`;
    } else {
      alert('Please enter a domain name');
    }
  };

  return (
    <div className="tldswrapper">
      <div className="domainwrapper">
        <div className="domainsearch">
          <input
            type="text"
            placeholder="Enter The Domain"
            value={domainName}
            onChange={(e) => setDomainName(e.target.value)}
            className="search-input"
          />
        </div>
        <div className="btndomain">
          <button onClick={handleSearch} className="search-button">
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default DomainSearch;
