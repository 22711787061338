
import React from "react";
// import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import image1 from '../assets/banner1-min.png'
import image2 from '../assets/banner2-min.png'
import image3 from '../assets/banner3-min.png'


// import herosec2 from "../../assets/images/herosec2.jpeg"
// import herosec3 from "../../assets/images/herosec3.jpeg"

export default function HomeBanner() {
    // var settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows:true,
    //     autoplay:true
    //   };

  return (
    <div className="homebannerSection">
       <div className=" ">
            <div className="homebannerSection mt-1">
            <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
        effect="fade"
        loop={true}
        autoplay='1000'
        
      >
          <SwiperSlide>        <div className="item">
            <img src={image1} className="w-100" alt="error"/>
        </div>
        </SwiperSlide>
        <SwiperSlide>      <div className="item">
            <img src={image2} className="w-100" alt="error"/>
        </div> </SwiperSlide> 
  
        <SwiperSlide> <div className="item">
            <img src={image3} className="w-100" alt="error"/>
        </div> </SwiperSlide> 
       

   

       </Swiper>
    </div>
       </div>
       </div>
  )
}

