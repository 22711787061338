import React from 'react'
import wordpressdescription1 from "../assets/wordpressdescription1-min.png"
import wordpressdescription2 from "../assets/wordpressdescription2-min.png"
import wordpressdescription3 from "../assets/wordpressdescription3-min.png"
import { MdOutlineLocalPhone, MdSecurity, MdSpeed, MdSupportAgent } from "react-icons/md";

import Topfeature from '../components/Topfeature';
import QASection from '../components/Questionmaries';

import {  FaGlobeAmericas, FaLock,  FaRocket, FaSearch, FaServer, FaShieldAlt, FaSyncAlt, FaWordpress } from "react-icons/fa";

import {  IoSpeedometerSharp } from "react-icons/io5";

import { SiMinutemailer } from "react-icons/si";
import {  FaRegHardDrive } from "react-icons/fa6";
import { LuPanelTop } from "react-icons/lu";
import { MdEmail } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";


import HomePricingComparision from '../components/HomePricingComparision';
import { Helmet } from 'react-helmet';
import WhyChooseUs from '../components/WhyChooseUs';
import CustomerReviews from '../components/CustomerReviews';
import VPSFeatures from '../components/VPSFeatures';
import { BiSolidCloudUpload } from 'react-icons/bi';

export default function wordpress() {

  
  const questions = [
    { 
      question: "What is WordPress hosting and how does Host Welcome support it?", 
      answer: "WordPress hosting is a hosting solution specifically optimized for WordPress websites. Host Welcome offers fast, reliable, and secure WordPress hosting, providing one-click installations, automatic updates, and optimized performance to ensure your WordPress site runs smoothly at all times."
    },
    { 
      question: "What are the key benefits of WordPress hosting with Host Welcome?", 
      answer: "Host Welcome’s WordPress hosting provides optimized performance for WordPress websites, enhanced security with free SSL certificates, automatic backups, and easy management through an intuitive control panel. With our WordPress-specific features, your site will load faster, be more secure, and provide a better experience for your visitors."
    },
    { 
      question: "How does Host Welcome’s WordPress hosting differ from other hosting options?", 
      answer: "Host Welcome’s WordPress hosting is specifically optimized for WordPress websites. Unlike general web hosting, our WordPress plans include features like one-click WordPress installation, automatic updates, faster speeds, and security enhancements tailored to WordPress. This allows for better performance and management of WordPress sites."
    },
    { 
      question: "What WordPress hosting plans does Host Welcome offer?", 
      answer: "Host Welcome offers a variety of WordPress hosting plans, each designed to meet the needs of different website sizes. Whether you're running a personal blog, a business site, or an e-commerce store, our plans include features like free SSL certificates, one-click installations, and enhanced security. Explore our [WordPress Hosting Plans](/wordpress-hosting) to find the best option for your website."
    },
    { 
      question: "Is WordPress hosting suitable for eCommerce websites?", 
      answer: "Yes, Host Welcome’s WordPress hosting is ideal for eCommerce websites. With our optimized environment, your WordPress eCommerce site (using WooCommerce or other plugins) will experience faster speeds, secure transactions with SSL, and a reliable hosting platform that ensures your online store is always up and running."
    },
    { 
      question: "How does Host Welcome ensure the security of WordPress websites?", 
      answer: "Host Welcome takes WordPress security seriously. We offer free SSL certificates, automated daily backups, malware scanning, and advanced firewalls to protect your WordPress site. Additionally, we ensure that WordPress core files and plugins are automatically updated to protect against vulnerabilities."
    },
    { 
      question: "Can I install plugins and themes on my WordPress site with Host Welcome?", 
      answer: "Yes, you can install any plugins or themes on your WordPress site with Host Welcome’s WordPress hosting. Our platform is fully compatible with thousands of WordPress plugins and themes, allowing you to customize your site’s functionality and appearance to suit your needs."
    },
    { 
      question: "What kind of customer support does Host Welcome offer for WordPress hosting?", 
      answer: "Host Welcome offers 24/7 customer support for all WordPress hosting clients. Whether you need help with WordPress installation, plugin management, performance optimization, or troubleshooting, our expert team is available via live chat, email, or phone to assist with any issues you may encounter."
    },
    { 
      question: "How fast will my WordPress site be with Host Welcome’s hosting?", 
      answer: "Host Welcome’s WordPress hosting is optimized for fast performance. With features like SSD storage, caching, and content delivery network (CDN) integration, your WordPress website will load quickly, offering a better user experience and improved SEO performance."
    },
    { 
      question: "Does Host Welcome offer a money-back guarantee for WordPress hosting?", 
      answer: "Yes, Host Welcome offers a 30-day money-back guarantee on all WordPress hosting plans. If you're not satisfied with the service, you can request a full refund within the first 30 days. For more details, visit our [Money-Back Guarantee page](/money-back-guarantee)."
    },
    { 
      question: "Can I migrate my existing WordPress site to Host Welcome?", 
      answer: "Yes, Host Welcome provides a hassle-free WordPress migration service. Our team can help you migrate your existing WordPress site from another host to our platform. Visit our [Migration page](/wordpress-migration) for more details on how we can assist with the transfer."
    }
  ];
  
  const HomePlans = [
    {
      name: "BASIC WORDPRESS",
      price: "3",
      cents: "99",
      color: "slate",
      features: [
        { icon: <FaServer />, text: "1 WordPress Website" },
        { icon: <FaRegHardDrive />, text: "10 GB SSD Storage" },
        { icon: <FaDatabase />, text: "5 MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL Certificate" },
        { icon: <MdEmail />, text: "10 Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel Control Panel" },
        { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
        { icon: <FaWordpress />, text: "Pre-Installed WordPress" },
      ],
    },
    {
      name: "STANDARD WORDPRESS",
      price: "5",
      cents: "99",
      color: "sky",
      bestSeller: true,
      features: [
        { icon: <FaServer />, text: "Unlimited WordPress Websites" },
        { icon: <FaRegHardDrive />, text: "50 GB SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL Certificate" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel Control Panel" },
        { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
        { icon: <FaWordpress />, text: "Pre-Installed WordPress" },
        { icon: <LuPanelTop />, text: "Daily Backups" },
      ],
    },
    {
      name: "BUSINESS WORDPRESS",
      price: "9",
      cents: "99",
      color: "green",
      features: [
        { icon: <FaServer />, text: "Unlimited WordPress Websites" },
        { icon: <FaRegHardDrive />, text: "100 GB SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL & SiteLock Security" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel + CloudLinux" },
        { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
        { icon: <FaWordpress />, text: "Pre-Installed WordPress" },
        { icon: <LuPanelTop />, text: "Daily Backups + Free CDN" },
      ],
    },
    {
      name: "PRO WORDPRESS",
      price: "15",
      cents: "99",
      color: "orange",
      features: [
        { icon: <FaServer />, text: "Unlimited WordPress Websites" },
        { icon: <FaRegHardDrive />, text: "Unlimited SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL, SiteLock & DDoS Protection" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel + CloudLinux + LiteSpeed" },
        { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
        { icon: <FaWordpress />, text: "Pre-Installed WordPress" },
        { icon: <LuPanelTop />, text: "Daily Backups + Free CDN + Priority Support" },
        { icon: <MdSecurity />, text: "Malware Scan & Removal" },
      ],
    },
  ];
  
  const features = [
    {
      id: 1,
      title: "Optimized for WordPress",
      description:
        "Our WordPress hosting is pre-configured for high performance with custom caching, LiteSpeed servers, and database optimization to ensure fast load times.",
      icon: <FaWordpress />,
    },
    {
      id: 2,
      title: "Automatic Updates",
      description:
        "We handle WordPress core, theme, and plugin updates automatically, keeping your site secure and up-to-date.",
      icon: <FaSyncAlt />,
    },
    {
      id: 3,
      title: "Blazing Fast Performance",
      description:
        "Our hosting is powered by SSD storage, LiteSpeed caching, and a CDN to ensure your website loads instantly, improving user experience and SEO rankings.",
      icon: <MdSpeed />,
    },
    {
      id: 4,
      title: "Free SSL & Security Protection",
      description:
        "Every WordPress hosting plan includes a free SSL certificate, DDoS protection, and a firewall to keep your site safe from cyber threats.",
      icon: <FaShieldAlt />,
    },
    {
      id: 5,
      title: "One-Click WordPress Installation",
      description:
        "Install WordPress with just one click, and get started with your website instantly. No technical knowledge required!",
      icon: <BiSolidCloudUpload />,
    },
    {
      id: 6,
      title: "24/7 WordPress Expert Support",
      description:
        "Our WordPress hosting experts are available 24/7 to assist you with technical issues, plugin recommendations, or performance optimizations.",
      icon: <MdSupportAgent  />,
    },
    {
      id: 7,
      title: "Scalability & Growth",
      description:
        "Easily scale your WordPress hosting plan as your website grows. Whether you're running a blog, business site, or eCommerce store, our hosting can handle your traffic.",
      icon: <FaRocket />,
    },
  ];
  return (

    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WordPress Hosting | Host Welcome</title>
        <meta name="description" content="Fast, secure, and reliable WordPress hosting with optimized features for seamless website performance, security, and scalability." />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* Open Graph and Twitter Card meta tags for better visibility on social media */}
        <meta property="og:title" content="WordPress Hosting | Host Welcome" />
        <meta property="og:description" content="Experience the best WordPress hosting with speed, security, and ease of use for a hassle-free website management experience." />
        <meta property="og:image" content="URL_to_image_for_og_image" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="WordPress Hosting | Host Welcome" />
        <meta name="twitter:description" content="Get reliable and high-performance WordPress hosting, optimized for speed, security, and smooth website operation." />
        <meta name="twitter:image" content="URL_to_image_for_twitter_card" />
      </Helmet>
       <header className='wordpress-banner'>
         <div className='shared-head-banner d-flex
         '>
             <span><FaWordpress /><p>Wordpress Hosting </p></span>
         </div>
         </header>
         <div className='number-bar'>
         <p className='pe-4'>Have any Question?</p>
         <p className='pe-4'>We have Precise answer. </p>
         <MdOutlineLocalPhone />
         
         <p>+92 3242163697</p>
         </div>
         <header/>

         <section>
          <div className='wordpress-des'>
            <div className='wordpress-des1'>
              <div className='wordpress-des1-1'>
          <h2>Default Themes</h2>
          <p>Our hand-picked selection of popular WordPress themes are sure to provide you with not only the features you want, but also the eye-pleasing design and mobile-friendliness that your customers and visitors will expect.</p>
            <button>Get Started</button>  
              
              </div>
              <div className='wordpress-des1-1-img'>
                <img src={wordpressdescription1} alt='error'/>
              </div>
            </div>





         
            <div className='wordpress-des2 d-flex'>
            <div className='wordpress-des2-2-img'>
                <img src={wordpressdescription2} alt='error'/>
              </div>
              <div className='wordpress-des2-2'>
          <h2>Pre Installed Plugins</h2>
          <p>Our hand-picked selection of popular WordPress themes are sure to provide you with not only the features you want, but also the eye-pleasing design and mobile-friendliness that your customers and visitors will expect.</p>
            <button>Get Started</button>  
              
              </div>

            </div>
       
            <div className='wordpress-des3 '>
            <div className='wordpress-des3-3'>
          <h2>Full Customized Control Panel</h2>
          <p>Our full customized control panel is sleek and intuitive and allows you to quickly and easily access all the WordPress settings that you use the most. Easy and user-friendly graphical interface.</p>
            <button>Get Started</button>  
              
              </div>
              <div className='wordpress-des3-3-img'>
                <img src={wordpressdescription3} alt='error'/>
              </div>
            </div>
          </div>
         </section>






         <section className='businesshostingplans'>
    <h2>Plan and Price</h2>
    <p>Here's the comparison chart to pick the correct hosting plan based on your needs and budget.</p>
    <HomePricingComparision HomePlans={HomePlans}/>
</section>
<VPSFeatures title="Why Choose Wordpress Hosting" features={features}/>

         <section><Topfeature/></section>
       <WhyChooseUs/>
       <CustomerReviews/>
         <QASection questions={questions}/>
  
    
    
    
    </>
  )
}
