import React from "react";
import { Link } from "react-router-dom";
import { FaBriefcase, FaWindows } from "react-icons/fa";
import { SiCpanel } from "react-icons/si";
import { FaLinux } from "react-icons/fa";
import { FaServer } from "react-icons/fa";
import { motion } from "framer-motion";

import { FaWordpress, FaCogs } from "react-icons/fa";  // Import WordPress and cPanel icons
import { GiGlobe } from "react-icons/gi";  // Import Globe icon for domain-related services
import { FaRegHardDrive } from "react-icons/fa6";
import { FaDatabase, FaGlobeAmericas, } from "react-icons/fa";
import { IoSpeedometerSharp } from "react-icons/io5";
import { MdEmail, } from "react-icons/md";
import { SiMinutemailer } from "react-icons/si";


import HomeBanner from "../components/HomeBanner";
import DomainSearch from "../components/DomainSearch";
import Domaintlds from "../components/Domaintlds";
import ProductFinderChart from "../components/ProductFinderChart";
import HostingGrid from "../components/HostingCard";
import { Helmet } from "react-helmet";

import QASection from "../components/Questionmaries";
import HomePricingComparision from "../components/HomePricingComparision";
import CustomerReviews from "../components/CustomerReviews";
import WhyChooseUs from "../components/WhyChooseUs";
import Topfeature from "../components/Topfeature";
import { IoMdContacts } from "react-icons/io";


export default function Home() {
  const HomePlans = [
    {
      name: "BASIC",
      price: "1",
      cents: "67",
      color: "slate",
      features: [
        { icon: <FaRegHardDrive />, text: "2gb Disk Space" },
        { icon: <IoSpeedometerSharp />, text: "20gb Monthly bandwidth" },
        { icon:<FaGlobeAmericas />, text: "10 Sub Domains" },
        { icon: <MdEmail />, text: "50 Email Accounts" },
        { icon: <SiMinutemailer />, text: "10 FTP Accounts" },
        { icon: <FaDatabase />, text: "05 MySQL Databases" },
       
    
      ],
       link:"https://hostwelcome.com/clients/cart.php?a=add&pid=2"
    },
    {
      name: "STANDARD",
      price: "2",
      cents: "50",
      color: "sky",
      bestSeller: true,
      features: [
        { icon: <FaRegHardDrive />, text: "5gb Disk Space" },
        { icon: <IoSpeedometerSharp />, text: "50gb Monthly bandwidth" },
        { icon:<FaGlobeAmericas />, text: "Unlimited Sub Domains" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "Unlimited FTP Accounts" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
       
      ],
       link:"https://hostwelcome.com/clients/cart.php?a=add&pid=3"
    },
    {
      name: "BUSINESS",
      price: "4",
      cents: "17",
      color: "green",
      features: [
        { icon: <FaRegHardDrive />, text: "10gb Disk Space" },
        { icon: <IoSpeedometerSharp />, text: "100gb Monthly bandwidth" },
        { icon:<FaGlobeAmericas />, text: "Unlimited Sub Domains" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "Unlimited FTP Accounts" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
       
      ],
       link:"https://hostwelcome.com/clients/cart.php?a=add&pid=4"
    },

  ];
  
  
 
  const hostingGrid = [
    {
      id: 1,
      title: "Business Hosting",
      badge: "NVMe SSD",
      icon: <FaBriefcase />, 
      text: "High-performance hosting package for websites with high traffic. Optimized for WordPress, Magento, and other CMS platforms.",
      link:"/business-hosting"
    },
    {
      id: 2,
      title: "Reseller Hosting",
      badge: "NVMe SSD",
      icon: <FaCogs />, 
      text: "Start your own hosting business with our Reseller Hosting. Complete control with WHM and cPanel.",
       link:"/reseller-hosting"
    },
    {
      id: 3,
      title: "Root Reseller Hosting",
      badge: "Premium",
      icon: <SiCpanel />,
      text: "Take full control of your hosting business with Root Reseller Hosting. Manage your clients' accounts directly.",
       link:"/rootreseller-hosting"
    },
    {
      id: 4,
      title: "WordPress Hosting",
      badge: "Optimized",
      icon: <FaWordpress />, // WordPress related icon for WordPress Hosting
      text: "Fully optimized hosting solution for WordPress sites. Lightning-fast speeds and top-notch security.",
       link:"/wordpress-hosting"
    },
    {
      id: 5,
      title: " Linux VPS ",
      badge: "Customizable",
      icon: <FaLinux />, // Server-related icon for VPS Hosting
      text: "Control Panel or pure, raw Linux? We have a VPS plan for every size and type of project. Enjoy increased control for your websites.",
       link:"/linux-vps"
    },
    {
      id: 6,
      title: " Windows VPS ",
      badge: "Customizable",
      icon: <FaWindows />, 
      text: "Control Panel or pure, raw Linux? We have a VPS plan for every size and type of project. Enjoy increased control for your websites.",
       link:"/windows-vps"
    },
    {
      id: 7,
      title: "Dedicated Server Hosting",
      badge: "Powerful",
      icon: <FaServer />, 
      text: "Dedicated servers offering complete resources and control. Ideal for high-traffic websites and businesses.",
       link:"/dedicated-server"
    },
    {
      id: 8,
      title: "Domain Registration",
      badge: "Get Started",
      icon: <GiGlobe />,
      text: "Register your domain name with us and get a professional online presence. Fast and easy domain registration.",
       link:"/domain-registration"
    },
    {
      id: 9,
      title: ".PK Domain Registration",
      badge: "Local Presence",
      icon: <GiGlobe />, 
      text: "Get a .PK domain for your business and cater to the Pakistani market with a local presence.",
       link:"/pk-domain-registration"
    }
  ];

  const questions = [
    { 
      question: "What is Host Welcome and how does it help with domain registration?", 
      answer: "Host Welcome is a trusted domain registration and hosting provider. We make it easy to register a domain name and get your website online. Learn more about our domain registration services on our [Domain Registration page](/domain-registration)."
    },
    { 
      question: "What services does Host Welcome offer?", 
      answer: "Host Welcome provides a variety of services, including domain registration, web hosting, SSL certificates, and website management. Explore our [Hosting Plans](/hosting-plans) to choose the best plan for your needs."
    },
    { 
      question: "How can I register a domain name with Host Welcome?", 
      answer: "You can easily register a domain name with Host Welcome using our domain search tool. Simply enter your desired domain name, check availability, and complete the registration. Visit our [Domain Registration page](/domain-registration) to get started."
    },
    { 
      question: "What domain extensions are available at Host Welcome?", 
      answer: "Host Welcome offers a wide selection of domain extensions such as .com, .net, .org, .co, .pk, and many more. Check out our [Domain Extensions page](/domain-extensions) to explore your options."
    },
    { 
      question: "How can I contact Host Welcome for support?", 
      answer: "You can reach our customer support team via live chat, email at support@hostwelcome.com, or by calling our helpline. For more details, visit our [Contact Us page](/contact-us)."
    },
    { 
      question: "What is the difference between shared hosting and VPS hosting at Host Welcome?", 
      answer: "Shared hosting is a great option for smaller websites, while VPS hosting offers more resources and control. Learn more about our hosting options on our [Hosting Plans page](/hosting-plans)."
    },
    { 
      question: "What are Host Welcome’s payment methods for domain registration?", 
      answer: "We accept multiple payment methods including credit/debit cards, PayPal, and bank transfers. For more payment options, visit our [Billing Information page](/billing-information)."
    },
    { 
      question: "Can I upgrade my hosting plan with Host Welcome?", 
      answer: "Yes, you can easily upgrade your hosting plan at any time. Visit our [Hosting Plans page](/hosting-plans) to view available upgrades."
    },
    { 
      question: "Is domain privacy protection included in Host Welcome’s services?", 
      answer: "Domain privacy protection is available as an add-on for an additional fee. It helps keep your personal information private. Learn more about domain protection on our [Privacy Protection page](/privacy-protection)."
    },
    { 
      question: "How can I transfer my domain to Host Welcome?", 
      answer: "Transferring your domain to Host Welcome is easy. Visit our [Domain Transfer page](/domain-transfer) for step-by-step instructions on how to transfer your domain."
    },
    { 
      question: "What is Host Welcome’s uptime guarantee?", 
      answer: "We offer a 99.9% uptime guarantee to ensure your website is always online. For more details, check our [Service Level Agreement](/sla)."
    },
    { 
      question: "Does Host Welcome offer a money-back guarantee?", 
      answer: "Yes, we offer a 30-day money-back guarantee on our hosting plans. For more details, see our [Money-Back Guarantee page](/money-back-guarantee)."
    },
  ];
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Host Welcome",
    url: "https://www.hostwelcome.com",
    logo: "https://www.hostwelcome.com/logo.png",
    sameAs: [
      "https://www.facebook.com/HostWelcome",
      "https://twitter.com/HostWelcome",
    ],
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+1234567890",
      contactType: "Customer Service",
      areaServed: "PK",
      availableLanguage: "en",
    },
  };




  return (
    <>
      <div>
        <Helmet>
          <title>Host Welcome - Best Web Hosting in Pakistan</title>
          <meta
            name="description"
            content="Host Welcome provides affordable, reliable, and high-performance managed web hosting and cloud hosting solutions across Pakistan. Our services are designed to meet the needs of businesses and individuals alike, offering seamless website hosting, fast loading speeds, robust security, and scalable resources. Whether you're a small business owner, a startup, or an established enterprise, we deliver top-notch hosting services with exceptional customer support. With a focus on reliability and cost-effectiveness, Host Welcome ensures that your website runs smoothly, efficiently, and securely, empowering your online presence in Pakistan."
          />
    
          <script type="application/ld+json">
            {JSON.stringify(schemaMarkup)}
          </script>
        </Helmet>
  
      </div>
      <div>
        <HomeBanner />
                  <div className='number-bar'>
                  <p className='pe-4'>Have any Question?</p>
                  <p className='pe-4'>We have Precise answer. </p>
                  <IoMdContacts />
                  
                  <p>+92 3242163697</p>
                  </div>
        <section className="hometldswrapper"> <DomainSearch /></section>
       

        <Domaintlds />
        <br />
       

        <div className="container weldes d-flex">
          <div className=" welcome">
            <span>WELCOME TO</span>
            <span className="d-flex">
              HOST<p className="ps-2">WELCOME</p>{" "}
            </span>
          </div>
          <div className="hostd">
            <span>
              HostWelcome Professional web hosting service providers in
              Pakistan. We offer reliable and affordable shared, VPS and
              dedicated server hosting and domain registrations! Join us for
              international standard support and services.
            </span>
          </div>
        </div>

        <ProductFinderChart />

        <div className="planofferhome">
          <h1>Solutions We Offer</h1>
          <HostingGrid hostingGrid={hostingGrid} />
        </div>
      </div>

    
      <HomePricingComparision HomePlans={HomePlans}/>

      
      <br />

      
      <br />
    <WhyChooseUs/>
    <Topfeature/>
      <motion.div
        className="description-box"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
      >
        <h1>Managed Web Hosting & Cloud Services in Pakistan</h1>

        <p>
          Host Welcome Private Limited offers affordable and{" "}
          <strong>reliable managed web hosting</strong> &{" "}
          <strong>cloud hosting services</strong> across Pakistan. We value your
          visit and thank you for considering our services. In Pakistan, many
          companies provide <strong>web hosting</strong> and{" "}
          <strong>domain registration</strong> services, but Host Welcome stands
          out for its reputation in the web hosting industry, offering a wide
          range of solutions. Whether you’re looking to{" "}
          <strong>buy web hosting</strong> or{" "}
          <strong>register a domain name</strong>, you're in the right place. We
          offer reliable services that give you full control over your hosting
          needs, allowing you to customize your service as you wish.
        </p>

        <h2>Reliable Web Hosting in Pakistan</h2>

        <p>
          Looking for{" "}
          <strong>cost-effective web hosting services in Pakistan</strong>? Host
          Welcome is the ideal choice for hosting your website. Our dedicated
          team ensures that our servers are up 24/7 and highly secure. We offer
          a variety of hosting options, including{" "}
          <strong>WordPress hosting</strong>, <strong>Linux VPS</strong>,{" "}
          <strong>Windows VPS</strong>, and more. As a rapidly growing business,
          we provide high-quality <strong>OpenVZ VPS</strong> and{" "}
          <strong>KVM VPS hosting</strong>, with dedicated servers and
          budget-friendly hosting plans for all needs.
        </p>

        <h2>What Is Web Hosting & Domain Registration?</h2>

        <p>
          <strong>Web Hosting</strong> is an essential online service that
          allows businesses and individuals to store data, emails, and websites
          on secure servers. Whether you’re hosting static or dynamic websites
          (like <strong>WordPress</strong>, <strong>Joomla</strong>,{" "}
          <strong>Magento</strong>, or <strong>PHP</strong>), web hosting allows
          your website to be accessible on the internet. At Host Welcome, we
          provide <strong>affordable and reliable web hosting</strong> and{" "}
          <strong>domain registration services in Pakistan</strong>, making it
          easier for businesses in cities like Karachi, Lahore, Islamabad,
          Gujranwala, and Sialkot to create an online presence.
        </p>

        <h2>Why Choose Host Welcome for Web Hosting Services?</h2>

        <p>
          Host Welcome is your trusted partner for{" "}
          <strong>web hosting in Pakistan</strong>, offering advanced and{" "}
          <strong>reliable hosting services</strong> at competitive prices. We
          specialize in a variety of hosting solutions including{" "}
          <strong>unlimited hosting</strong>,{" "}
          <strong>business web hosting</strong>,{" "}
          <strong>WordPress managed hosting</strong>, and much more. Our{" "}
          <strong>domain registration</strong> services come with full control,
          including options to manage nameservers, attach your domain with
          platforms like Blogger, and a knowledge base for easy guidance.
        </p>

        <h2>Our Hosting Plans</h2>

        <p>
          We offer affordable plans for every need:
          <ul>
            <li>
              <strong>
                <Link to="/Shared-web-hosting" title="Budget Web Hosting">
                  Shared Web Hosting
                </Link>
              </strong>{" "}
              for small websites and startups.
            </li>
            <li>
              <strong>
                <Link to="/business-web-hosting" title="Business Web Hosting">
                  Business Web Hosting
                </Link>
              </strong>{" "}
              with increased resources for business websites.
            </li>
            <li>
              <strong>
                <Link
                  to="/managed-wordpress-hosting"
                  title="Managed WordPress Hosting"
                >
                  Managed WordPress Hosting
                </Link>
              </strong>{" "}
              with optimized performance for WordPress sites.
            </li>
            <li>
              <strong>
                <Link to="/vps-hosting" title="VPS Hosting">
                  VPS Hosting
                </Link>
              </strong>{" "}
              (Linux and Windows) for better control and flexibility.
            </li>
            <li>
              <strong>
                <Link to="/dedicated-servers" title="Dedicated Servers">
                  Dedicated Servers
                </Link>
              </strong>{" "}
              for high-traffic websites requiring top performance.
            </li>
          </ul>
          At Host Welcome, we also offer additional services such as:
          <ul>
            <li>
              <strong>
                <Link to="/server-management" title="Server Management">
                  Server Management
                </Link>
              </strong>{" "}
              for optimal server performance and security.
            </li>
            <li>
              <strong>
                <Link to="/reseller-hosting" title="Reseller Hosting">
                  Reseller Hosting
                </Link>
              </strong>{" "}
              for entrepreneurs and businesses wanting to offer hosting
              services.
            </li>
            <li>
              <strong>
                <Link to="/root-reseller-hosting" title="Root Reseller Hosting">
                  Root Reseller Hosting
                </Link>
              </strong>{" "}
              for advanced users who need full control over their hosting
              environment.
            </li>
          </ul>
        </p>
        <h2>Contact Host Welcome Today!</h2>

        <p>
          Choose Host Welcome for{" "}
          <strong>affordable web hosting in Pakistan</strong> with top-notch
          customer support available 24/7. <strong>Get started today</strong>{" "}
          and experience the best hosting services with full cPanel access,
          enhanced security, and a wide range of hosting options for all types
          of businesses.
          <Link
            to="/contact-us"
            title="Contact Host Welcome for Web Hosting Services"
          >
            Contact us
          </Link>{" "}
          to learn more about our hosting packages and get personalized
          recommendations based on your needs.
        </p>
      </motion.div>
      
       <CustomerReviews/>
      <QASection  questions={questions}/>
    </>
  );
}
