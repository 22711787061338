import { FaMoneyBillWave, FaThumbsUp, FaLock, FaPhone } from "react-icons/fa";
// import "./styles.css";

const features = [
  {
    icon: <FaMoneyBillWave />, 
    title: "Money Back Guarantee",
    description: "If you are not fully satisfied with our Personal Hosting services, you can cancel within the first 30 days and get a full refund of your money. After 30 days, you can receive pro-rate refund upon cancellation. No questions asked."
  },
  {
    icon: <FaThumbsUp />, 
    title: "Zero Overselling Guarantee",
    description: "We follow ONE simple rule: You EXACTLY get what you pay for. No excuses, no gimmicks, no lies. Since our servers are never overloaded, you may use 100% of your account resources without getting suspended."
  },
  {
    icon: <FaLock />, 
    title: "Free SSL",
    description: "For small and growing businesses, we’d recommend free SSL Certificate included in our plans offers basic website protection. It allows you to setup trusted HTTPS on websites for encrypted connections with extra fee."
  },
  {
    icon: <FaPhone />, 
    title: "15 Min Response Guarantee",
    description: "After we receive your new ticket, you will get the initial response from our support team member within 15 Minutes. This response will be from a human and NOT an automated response. Our support team works 24x7x365 for you."
  }
];

export default function  Topfeature() {
  return (
    <div className="topfeature-box container">
        <h1>We have got you covered Guaranteed!</h1>
        <h4>Our 4x guarantees will give you peace of mind all the time.</h4>
    <div className="features-container">
      {features.map((feature, index) => (
        <div key={index} className="feature-box">
          <div className="feature-icon">{feature.icon}</div>
          <h3 className="feature-title">{feature.title}</h3>
          <p className="feature-text">{feature.description}</p>
        </div>
      ))}
    </div>
    </div>
  );
}
