import React from "react";
import { IoMdContacts } from "react-icons/io";
import { FaRegThumbsUp } from "react-icons/fa6";


const TermsAndPolicies = () => {
  return (
    <>
    <div className='business-hosting-banner
        '>
            <header>
            <div className='shared-head-banner d-flex
            '>
                <span><FaRegThumbsUp /><p>
                Terms & Policies </p></span>
            </div>
            </header>
            <div className='number-bar'>
            <p className='pe-4'>Have any Question?</p>
            <p className='pe-4'>We have Precise answer. </p>
            <IoMdContacts />
            
            <p>+92 3242163697</p>
            </div>
        </div>
    <div className="containertermsandpolicies">
      <h1 className="title">Terms & Policies</h1>
      
      <section className="section">
        <h2 className="subtitle">Unlimited / Unmetered Hosting Packages</h2>
        <p>
          When we say unlimited domains, unlimited disk space, and bandwidth, of course, there are terms & conditions that you must adhere to. This means unlimited web pages, domains, and web graphics, not downloads or streaming media. Our servers are shared hosting servers and memory and CPU usage are not unlimited...
        </p>
      </section>

      <section className="section">
        <h2 className="subtitle">Free Hosting</h2>
        <p>
          Free hosting is not permitted with unlimited packages. If you intend to offer free domain-based hosting, please choose one of our metered packages...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Web Server</h2>
        <p>
          Our servers are web servers. Web server means file hosting that you can view in a browser...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Data Backup & Backup Policy</h2>
        <p>
          Although regular backups will be performed, we are not responsible for any loss of data. Users must maintain local backups...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Acceptable Use Policy</h2>
        <p>
          Users must adhere to respectful and lawful internet use. Offending content or users are removed from our network...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Illegality & Undesirable Content</h2>
        <p>
          Any form of unauthorized distribution or copying of copyrighted software, harassment, fraud, or trafficking in obscene material is prohibited...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Bulk Email & Spamming</h2>
        <p>
          The use of our network to send bulk email and spamming is strictly forbidden...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Misuse Of Resources</h2>
        <p>
          Employing applications that consume excessive CPU time, memory, or storage space is prohibited...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Adult Sites</h2>
        <p>
          Adult commercial sites including adult toy e-commerce, Viagra sales, and pornography are not permitted...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Refund Policy</h2>
        <p>
          Each of our shared and reseller hosting packages carries a 30-day unconditional money-back guarantee...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Moving to Other Host</h2>
        <p>
          In case of moving to another host, the contract will be canceled, and we are not liable for any data loss...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Domain Registration & Renewal</h2>
        <p>
          We do not provide a money-back guarantee for domain registration and reseller accounts...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Server Management</h2>
        <p>
          We provide server management services including OS installation, email troubleshooting, DNS management, and cPanel installation...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Penalties</h2>
        <p>
          If illegal content is found, the account will be suspended immediately with penalties ranging from $50 to $500...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Prices and Fees</h2>
        <p>
          Prices are subject to change at any time without notification...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Payment Policy</h2>
        <p>
          Please pay invoices before the due date to avoid service suspension. Restoration charges apply after termination...
        </p>
      </section>
      
      <section className="section">
        <h2 className="subtitle">Shipping Policy</h2>
        <p>
          We only sell digital products. No physical items will be shipped.
        </p>
      </section>
      
      <footer className="footer">
        <p>© <span id="year"></span> Host Welcome. All rights reserved.</p>
      </footer>
    </div>
    </>
  );
};

export default TermsAndPolicies;
