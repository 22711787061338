import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";



const CustomerReviews = () => {
  const reviews = [
    { id: 1, name: "Ahmed Shahzad", review: "Excellent service! Great support and top-quality products. Highly recommended." },
    { id: 2, name: "Fatima Malik", review: "Amazing experience! Fast delivery and great value for money." },
    { id: 3, name: "Usman Tariq", review: "Affordable and reliable. Impressed by the professionalism and timely service." },
    { id: 4, name: "Sara Butt", review: "Outstanding customer service! Kept me informed throughout the process." },
    { id: 5, name: "Raza Khan", review: "Smooth ordering experience. Quick delivery and great product quality." },
    { id: 6, name: "Madiha Jamil", review: "Professional and efficient! My order arrived on time with excellent quality." },
    { id: 7, name: "Bilal Ahmed", review: "Best shopping experience! Courteous support and fast delivery." },
    { id: 8, name: "Ayesha Khan", review: "Great service! Fast shipping and attentive customer support." },
    { id: 9, name: "Zain Ali", review: "High-quality products and seamless delivery. Highly recommended!" },
    { id: 10, name: "Sana Iqbal", review: "Smooth process from order to delivery. Very satisfied!" },
    { id: 11, name: "Shahzaib Rahman", review: "Great experience! Quick responses and early delivery." },
    { id: 12, name: "Hina Malik", review: "Reliable and affordable. Excellent quality and support." },
    { id: 13, name: "Fahad Mustafa", review: "Attention to detail is impressive! Fast and quality service." },
    { id: 14, name: "Zoya Farooq", review: "User-friendly website and fast shipping. Highly recommended!" },
    { id: 15, name: "Rashid Rehman", review: "Fast processing and excellent customer support. Very satisfied!" },
  ];
  
  
  return (
    <div className="reviews-container-wrapper">
    <div className="reviews-container">
      <h2 className="reviews-title">Customer Reviews</h2>
      <h3>We always appreciate feedback from our customers, both excellent and even constructive.</h3>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{ clickable: true }}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        className="mySwiper"
      >
        {reviews.map((review) => (
          <SwiperSlide key={review.id} className="review-slide">
            <div className="review-card">
              <p className="review-text">"{review.review}"</p>
              <h3 className="review-author">- {review.name}</h3>
            </div>
          </SwiperSlide>
        ))}

        {/* Custom navigation buttons */}
        <div
          className="swiper-button-prev custom-nav"
        ></div>
        <div
          className="swiper-button-next custom-nav"
        ></div>
      </Swiper>
    </div>
    </div>
  );
};

export default CustomerReviews;
