import React from 'react'
import ssl1 from "../assets/ssl1-min.png"
import ssl2 from "../assets/ssl2-min.jpg"
import { IoMdContacts } from "react-icons/io"; 
import { FaExpeditedssl } from "react-icons/fa6";
import { FaClock, FaGlobeAmericas, FaShieldAlt } from "react-icons/fa";

import { IoShieldCheckmark, } from "react-icons/io5";

import { MdBusiness, MdLock, MdSecurity, MdUpdate, MdVerified } from "react-icons/md";

import { LuPanelTop } from "react-icons/lu";

// import { Card } from "react-bootstrap";
import { ShieldLockFill, Search, LockFill } from "react-bootstrap-icons";

import QASection from '../components/Questionmaries';
import CustomerReviews from '../components/CustomerReviews';
import HomePricingComparision from '../components/HomePricingComparision';
import { Helmet } from 'react-helmet';
import WhyChooseUs from '../components/WhyChooseUs';
import Topfeature from '../components/Topfeature';
export default function SslSertificate() {

       


  const HomePlans = [
    {
      name: "BASIC SSL",
      price: "1",
      cents: "99",
      color: "slate",
      features: [
        { icon: <MdLock />, text: "Domain Validation (DV) SSL" },
        { icon: <FaGlobeAmericas />, text: "Single Domain Protection" },
        { icon: <MdVerified />, text: "Browser Padlock & HTTPS" },
        { icon: <FaShieldAlt />, text: "2048-bit Encryption" },
        { icon: <IoShieldCheckmark />, text: "Free SSL Installation" },
        { icon: <MdUpdate />, text: "Auto Renewal Support" },
        { icon: <FaClock />, text: "Issued in Minutes" },
        { icon: <LuPanelTop />, text: "cPanel Integration" },
      ],
    },
    {
      name: "STANDARD SSL",
      price: "5",
      cents: "99",
      color: "sky",
      bestSeller: true,
      features: [
        { icon: <MdLock />, text: "Organization Validation (OV) SSL" },
        { icon: <FaGlobeAmericas />, text: "Multiple Domain Protection" },
        { icon: <MdVerified />, text: "Trust Indicator & HTTPS" },
        { icon: <FaShieldAlt />, text: "256-bit Encryption" },
        { icon: <IoShieldCheckmark />, text: "Free Installation & Setup" },
        { icon: <MdUpdate />, text: "Auto Renewal Support" },
        { icon: <FaClock />, text: "Issued in 1-2 Days" },
        { icon: <LuPanelTop />, text: "cPanel & WHM Compatible" },
      ],
    },
    {
      name: "BUSINESS SSL",
      price: "9",
      cents: "99",
      color: "green",
      features: [
        { icon: <MdLock />, text: "Extended Validation (EV) SSL" },
        { icon: <FaGlobeAmericas />, text: "Multi-Domain & Subdomains" },
        { icon: <MdVerified />, text: "Green Address Bar (Trust Seal)" },
        { icon: <FaShieldAlt />, text: "Highest Encryption (256-bit)" },
        { icon: <IoShieldCheckmark />, text: "Free Installation & Setup" },
        { icon: <MdUpdate />, text: "Auto Renewal Support" },
        { icon: <FaClock />, text: "Issued in 3-5 Days" },
        { icon: <MdBusiness />, text: "Strict Business Verification" },
        { icon: <LuPanelTop />, text: "cPanel, WHM & Plesk Support" },
      ],
    },
    {
      name: "PRO SSL",
      price: "15",
      cents: "99",
      color: "orange",
      features: [
        { icon: <MdLock />, text: "Wildcard & Multi-Domain SSL" },
        { icon: <FaGlobeAmericas />, text: "Unlimited Subdomains" },
        { icon: <MdVerified />, text: "Highest Trust & Security" },
        { icon: <FaShieldAlt />, text: "Advanced 256-bit Encryption" },
        { icon: <IoShieldCheckmark />, text: "Free Installation & Priority Setup" },
        { icon: <MdUpdate />, text: "Auto Renewal & Expiry Alerts" },
        { icon: <FaClock />, text: "Issued in 1-5 Days" },
        { icon: <LuPanelTop />, text: "cPanel, WHM, Plesk & Custom Server Support" },
        { icon: <MdSecurity />, text: "DDoS Protection & Malware Scanning" },
      ],
    },
  ];
  
    const benefits = [
        {
          icon: <ShieldLockFill size={40} classnameName="text-success" />,
          title: "The Best SSL Encryption",
          description:
            "Buy SSL Certificate encryption and rely on strong security. Communication is safeguarded with SHA-256, 2048-bit RSA keys, and ECC support."
        },
        {
          icon: <Search size={40} classnameName="text-primary" />,
          title: "SEO Boost Found Faster",
          description:
            "Google ranks SSL-secured websites higher and flags non-secure ones. Don't lose customers—get an SSL now!"
        },
        {
          icon: <LockFill size={40} classnameName="text-warning" />,
          title: "Visitor Trust Points",
          description:
            "Your encryption comes with a Site Seal and browser padlock, reassuring customers that their data is safe."
        },
        {
          icon: <ShieldLockFill size={40} classnameName="text-secondary" />,
          title: "Flexible Cover",
          description:
            "Covers both 'www' and 'non-www' versions of your site, ensuring seamless protection across all servers."
        }
      ];
      const questions = [
        { 
          question: "Why is my website showing an SSL certificate not found error?", 
          answer: "The SSL certificate for your domain is missing. Visitors may see a 'Your connection is not private' warning.\nSolution: Install a valid SSL certificate through your hosting panel or contact Host Welcome support for assistance."
        },
        { 
          question: "What does the SSL certificate mismatch error mean?", 
          answer: "The SSL certificate does not match your domain name, causing browsers to display a security warning.\nSolution: Check your SSL certificate details and ensure it is issued for the correct domain."
        },
        { 
          question: "Why is my website showing an expired SSL certificate error?", 
          answer: "Your SSL certificate has expired, leading to security warnings when visitors access your site.\nSolution: Renew your SSL certificate immediately and install the updated version on your server."
        },
        { 
          question: "What causes the SSL protocol error on my website?", 
          answer: "A secure connection could not be established due to outdated TLS versions or incorrect encryption settings.\nSolution: Enable TLS 1.2 or 1.3 and verify your server's SSL configuration."
        },
        { 
          question: "Why am I getting a mixed content warning on my HTTPS website?", 
          answer: "Your HTTPS website is loading some resources (images, scripts, styles) over an insecure HTTP connection.\nSolution: Update all resource URLs to HTTPS to maintain a secure connection."
        }
      ];
      
      
  return (
  <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>SSL Certificates | Host Welcome</title>
        <meta name="description" content="Secure your website with trusted SSL certificates for enhanced security and user trust." />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* You can include Open Graph and Twitter Card meta tags for social media sharing */}
        <meta property="og:title" content="SSL Certificates | Host Welcome" />
        <meta property="og:description" content="Get SSL certificates for enhanced website security and customer trust." />
        <meta property="og:image" content="URL_to_image_for_og_image" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="SSL Certificates | Host Welcome" />
        <meta name="twitter:description" content="Secure your website with trusted SSL certificates for enhanced security and user trust." />
        <meta name="twitter:image" content="URL_to_image_for_twitter_card" />
      </Helmet>
   <div className='business-hosting-banner
      '>
          <header>
          <div className='shared-head-banner d-flex
          '>
              <span><FaExpeditedssl /><p>
              Rapid SSL Certificates</p></span>
          </div>
          </header>
          <div className='number-bar'>
          <p className='pe-4'>Have any Question?</p>
          <p className='pe-4'>We have Precise answer. </p>
          <IoMdContacts />
          
          <p>+92 3242163697</p>
          </div>
      </div>
      <div className="containersslwrapper">
      <div className="image-section">
        <img
          src={ssl1}
          alt="SSL Certificate"
          className="ssl-image"
        />
      </div>
      <div className="content-section">
        <h1>What do you get with PositiveSSL?</h1>
        <p>All the security you demand!</p>
        
        <h2>Why get an SSL certificate?</h2>
        <p>An SSL reduces your risk by keeping sensitive data collected on your website secure. Build customer confidence in your website and help drive more sales.</p>
        
        <h2>SSL certificate benefits</h2>
        <p>Customer trust is why purchasing SSL Certificates is good for websites.</p>
        <p>No hassles and great customer care are why we're good for you.</p>
        
        <a href="https://www.yourdomain.com" className="cta-button">Learn More</a>
      </div>
    </div>


      <div className="benefits-container py-5 container">
        <h1>SSL certificate benefits</h1>
        <p>Customer trust is why purchasing SSL Certificates is good for websites.
        No hassles and great customer care are why we’re good for you.</p>
  <div className="benefits-row">
    {benefits.map((benefit, index) => (
      <div key={index} className="benefit-item">
        <div className="benefit-card">
          <div className="benefit-icon mb-3">{benefit.icon}</div>
          <h5 className="benefit-title">{benefit.title}</h5>
          <p className="benefit-description">{benefit.description}</p>
        </div>
      </div>
    ))}
  </div>
</div>
<section className='businesshostingplans'>
    <h2>Plan and Price</h2>
    <p>Here's the comparison chart to pick the correct hosting plan based on your needs and budget.</p>
    <HomePricingComparision  HomePlans={HomePlans}/>
    <div className="google-ranking-section">
  <div className="google-ranking-image">
    <img src={ssl2} alt="Google Security Blog" />
  </div>
  <div className="google-ranking-content">
    <h3>Boost Google Rankings</h3>
    <p>Good news. Google says that SSL security has a positive effect on its rankings:</p>
    <blockquote>
      “We’re starting to use HTTPS as a ranking signal… for sites (that) use secure, encrypted connections (and) we’ve seen positive results.”
    </blockquote>
    <p><a href={ssl2} target="_blank" rel="noopener noreferrer">Google Online Security Blog</a></p>
  </div>
</div>

</section>


<div className="ssl-disclaimer">
  <p className="disclaimer-text">
    <strong>Disclaimer:</strong><br />
    <span className="disclaimer-point">
      *SSL certificates must be installed on your web server. Therefore, an end-to-end SSL installation support is possible only in cases where the domain is hosted at eHostPK.com. If the domain is hosted with any other provider, please contact their support for assistance with CSR generation and SSL installation.
    </span>
  </p>
  <p className="disclaimer-text">
    <strong>**</strong> Selected orders can be flagged for an additional “Brand Validation” procedure by Certificate Authority. This is a necessary security measure. If the order is flagged for “Brand Validation”, SSL issuance can be delayed for a time period deemed necessary by the Certificate Authority.
  </p>
</div>
<WhyChooseUs/>
<Topfeature/>
<CustomerReviews/>

<QASection questions={questions}/>




  </>
  )
}
