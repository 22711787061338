import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
// import Contact from './pages/Contact';
import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css';



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Navbar2 from './components/Navbar2';
import Domain from './pages/Domain';
import Footer from './components/Footer';
import PkDomains from './pages/PkDomains';
import SharedHosting from './pages/SharedHosting';
import Contactus from './pages/Contactus';
import AboutUs from './pages/AboutUs';
import Wordpress from './pages/Wordpress';
import Businesshosting from './pages/Businesshosting';
import ResellerHosting from './pages/ResellerHosting';
import RootReseller from './pages/RootReseller';
import WindowVserver from './pages/WindowVserver';
import TermsAndPolicies from './pages/TermsAndPolicies';
import SslSertificate from './pages/SslSertificate';
import ServerManagement from './pages/ServerManagement';
import HeaderStrip from './components/HeaderStrip';
import DedicatedServer from './pages/DedicatedServer';
import LinuxVserver from './pages/LinuxVserver';






function App() {


  return (
    <div>
      {/* <HeaderStrip/> */}
      <Navbar2/>
 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/domain-registration" element={<Domain />} />
        <Route path="/pk-domain-registration" element={<PkDomains />} />
        <Route path="/basic-web-hosting" element={<SharedHosting />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/terms" element={<TermsAndPolicies />} />
        <Route path="/wordpress-hosting" element={<Wordpress />} />
        <Route path="/windows-vps" element={<WindowVserver />} />
        <Route path="/linux-vps" element={<LinuxVserver />} />
        <Route path="/dedicated-server" element={<DedicatedServer />} />
        <Route path="/reseller-hosting" element={<ResellerHosting/>} />
        <Route path="/rootreseller-hosting" element={<RootReseller />} />
        <Route path="/business-hosting" element={<Businesshosting />} />
        <Route path="/ssl-certificate" element={<SslSertificate/>} />
        <Route path="/server-management" element={<ServerManagement/>} />

      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
