import React from 'react';
import cpanelimage from "../assets/cpanel-min.png"
import softaculous from "../assets/softaculous-min.jpg"


const DemoBox = () => {
  const handleButtonClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="demo-box">
        <h2>try our demo now . its free</h2>
      <div className="columns-container">
        {/* cPanel Column */}
        <div className="column  clom1">
          <div className="image-container">
            <img 
              src={cpanelimage}
              alt="cPanel Demo" 
              className="demo-image"
            />
          </div>
          <h3>Manage your domains, files, and emails easily via our cPanel interface.</h3>
          <button onClick={() => handleButtonClick('https://example.com/cpanel-demo')}>
            Get Started (cPanel)
          </button>
        </div>

        {/* Softaculous Column */}
        <div className="column clom2">
          <div className="image-container">
            <img 
              src={softaculous}
              alt="Softaculous Demo" 
              className="demo-image"
            />
          </div>
          <h3>Install 350+ popular apps like WordPress & Joomla with just a mouse click.</h3>
          <button onClick={() => handleButtonClick('https://example.com/softaculous-demo')}>
            Get Started (Softaculous)
          </button>
        </div>
      </div>
    </div>
  );
};

export default DemoBox;
