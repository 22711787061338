import { BookOpen } from 'lucide-react';
import React from 'react'
import { Helmet } from 'react-helmet';
import { FaHeadset, FaLock, FaServer } from "react-icons/fa";
import { MdOutlineLocalPhone } from "react-icons/md";
import aboutus1 from "../assets/about-usbanner-min.jpg"



export default function AboutUs() {
  return (
   <>
     <Helmet>
        <title>About Host Welcome - Reliable Web Hosting in Pakistan</title>
        <meta 
          name="description" 
          content="Host Welcome is a trusted web hosting company in Pakistan, offering domain registration, cPanel hosting, VPS, and dedicated servers with top-notch support. Learn more about our journey and why thousands of businesses trust us for hosting solutions." 
        />
      </Helmet>
   <header>
     <div className='about-head-banner d-flex
     '>
         {/* <span><FaServer /><p>Contact Us </p></span> */}
     </div>
     </header>
     <div className='number-bar'>
     <p className='pe-4'>Have any Question?</p>
     <p className='pe-4'>We have Precise answer. </p>
     <MdOutlineLocalPhone />
     
     <p>+92 3242163697</p>
     </div>

     <div className="great-at-container">
      <div className="text-section">
        <h2>We are Great at</h2>
        <div className="content">
          <h3>Popular CMS</h3>
          <p>
            We support WordPress, a robust open source CMS for the web. We help our customers to create better websites by providing expert-level support.
          </p>
        </div>
        <div className="content">
          <h3>Website Speed</h3>
          <p>
            Our WordPress & Business plans allow our customers to grow as website creators and business owners.
          </p>
        </div>
        <div className="content">
          <h3>Dedicated 24/7 Support</h3>
          <p>
            For every committed online solution finder with the ambition to rock the web.
          </p>
        </div>
      </div>


  

      <div className="image-section">
        <img src={aboutus1} alt="We are great at" />
      </div>
    </div>


   </>
  )
}
