import React from 'react';
import { FaCheckCircle, FaEye, FaClock, FaMinus, FaGlobe } from 'react-icons/fa';

const DomainTips = () => {
  const tips = [
    {
      icon: <FaCheckCircle aria-label="Check circle icon" />,
      title: "Keep it simple",
      description: "Simple domain names are easy to remember and can improve your website's SEO, representing your business clearly."
    },
    {
      icon: <FaEye aria-label="Eye icon" />,
      title: "Be Unique",
      description: "Choose a brandable domain name. Avoid alternative spellings like 'frndz' as they are harder to remember and less SEO-friendly."
    },
    {
      icon: <FaClock aria-label="Clock icon" />,
      title: "Book Quickly",
      description: "A domain name you like might not be available tomorrow. Secure your preferred domain name today to avoid missing out."
    },
    {
      icon: <FaMinus aria-label="Minus icon" />,
      title: "Avoid Hyphens",
      description: "Hyphens in domain names can negatively impact SEO and make the name harder to remember. Stick to simple, clean names."
    },
    {
      icon: <FaGlobe aria-label="Globe icon" />,
      title: "Think about other extensions",
      description: "If your preferred domain name is taken in .com, consider other TLDs like .net, .org, or newer extensions like .tech to keep your brand intact."
    }
  ];

  return (
    <section className="tips-container">
      <h2>Top Tips for Domain Registration</h2>
      <p>Follow these expert tips to choose the perfect domain name for your website and improve your SEO and brand visibility.</p>
      <div className="tips-list">
        {tips.map((tip, index) => (
          <article className="tip-item" key={index}>
            <div className="tip-number">{index + 1}</div>
            <div className="tip-content">
              <div className="tip-icon">{tip.icon}</div>
              <div className="tip-details">
                <h3>{tip.title}</h3>
                <p>{tip.description}</p>
              </div>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default DomainTips;
