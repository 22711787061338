import React from "react";
import { FaServer, FaLinux, FaCloud } from "react-icons/fa";
import { SiCpanel } from "react-icons/si";
import { Link } from "react-router-dom";

const productList = [
  { name: "Reseller-Hosting", icon: <SiCpanel />, ribbon: "NVMe SSD" },
  { name: "Dedicated Server", icon: <FaServer />, ribbon: "New" },
  { name: "Linux VPS", icon: <FaLinux />, ribbon: "NVMe SSD" },
  { name: "Cloud Instance", icon: <FaCloud />, ribbon: "NVMe SSD" },
];

const ProductFinderChart = () => {
  return (
    <div className="product-finderwrapper">
      <h2>Product Finder Chart</h2>
      <p>
        Compare the major features across all our web hosting products. This chart may seem a bit exhaustive, but it's the true birds-eye-view of all that we have to offer.
      </p>

      <div className="productchartbox">
        {productList.map((product, index) => (
          <Link
            to={`/${product.name.toLowerCase().replace(/\s+/g, '-')}`}
            title={`Learn more about ${product.name}`}
            key={index}
            className="productfinderchart" // Add a class to the Link
            style={{ textDecoration: "none", position: "relative" }} // Prevent link underline and set relative positioning
          >
            {/* Ribbon Badge */}
            <div
              
            >
              {product.ribbon}
            </div>

            {/* Product Icon and Name */}
            <span>{product.icon}</span>
            <h3>{product.name}</h3>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProductFinderChart;
