import React from "react";
import DomainSearch from "../components/DomainSearch";
import DomainTips from "../components/DomainTips";
import Questionmaries from "../components/Questionmaries";
import { Link } from "react-router-dom";
import styles from "./DomainRenewalBlog.module.css";
import { Helmet } from "react-helmet";
import WhyChooseUs from "../components/WhyChooseUs";
import Topfeature from "../components/Topfeature";
import { IoMdContacts } from "react-icons/io";

export default function PkDomains() {
  const questions = [
    {
      question: "How do I register a .pk domain with Host Welcome?",
      answer:
        "Registering a .pk domain with Host Welcome is simple and secure. Follow these steps: 1. Use our [PK Domain Search Tool](/pk-domain-registration) to check domain availability. 2. Choose from popular .pk domain extensions like .com.pk, .net.pk, .org.pk, and .edu.pk. 3. Complete the checkout process with secure payment options. 4. Once verified, your .pk domain will be registered instantly. Start your online journey today with a trusted **.pk domain provider**. Learn more on our [PK Domain Registration page](/pk-domain-registration).",
    },
    {
      question: "Why should I choose a .pk domain for my website?",
      answer:
        "A .pk domain is the best choice for businesses, organizations, and individuals targeting Pakistan. It enhances local SEO, builds trust among Pakistani users, and improves website ranking on **Pakistan-based search engines**. Secure your identity with a professional **.pk domain name** today! Check available domains on our [PK Domain Registration page](/pk-domain-registration).",
    },
    {
      question: "What .pk domain extensions are available for registration?",
      answer:
        "Host Welcome offers a variety of **Pakistan domain extensions**, including: <br>✅ **.pk** – For general use <br>✅ **.com.pk** – For businesses <br>✅ **.net.pk** – For networking companies <br>✅ **.org.pk** – For non-profits <br>✅ **.edu.pk** – For educational institutions <br>✅ **.gov.pk** – For government entities (restrictions apply). Choose the best **.pk domain name** that fits your needs on our [PK Domain Registration page](/pk-domain-registration).",
    },
    {
      question: "Can I transfer my .pk domain to Host Welcome?",
      answer:
        "Yes, you can transfer your existing .pk domain to Host Welcome in just a few easy steps. Our expert team ensures a **smooth and hassle-free domain transfer**. Visit our [Domain Transfer page](/pk-domain-transfer) and initiate your **.pk domain transfer** today.",
    },
    {
      question: "Is domain privacy protection available for .pk domains?",
      answer:
        "Yes, we offer **WHOIS privacy protection** for eligible .pk domains to safeguard your personal details from public exposure. Keep your data safe and prevent spam with our domain privacy protection. You can enable this feature during the registration process or through your [Host Welcome account](/account).",
    },
    {
      question: "How much does a .pk domain cost at Host Welcome?",
      answer:
        "**Host Welcome offers affordable .pk domain registration rates** with no hidden fees. Get a **.pk domain starting from just PKR 3200 for 2 years**. Check our latest prices and **buy a .pk domain** today on our [PK Domain Pricing page](/pk-domain-pricing).",
    },
    {
      question:
        "What are the benefits of registering a .pk domain with Host Welcome?",
      answer:
        "When you **register a .pk domain** with Host Welcome, you get: <br>✅ Affordable pricing with no hidden costs <br>✅ Fast and secure domain registration <br>✅ **24/7 customer support** <br>✅ Easy domain management tools <br>✅ Free domain forwarding & DNS management. <br>Secure your online presence today on our [PK Domain Registration page](/pk-domain-registration).",
    },
    {
      question: "How long does it take to register a .pk domain?",
      answer:
        "**.pk domain registration** is usually completed within 24-48 hours after payment confirmation. If manual verification is required, it may take longer. Get started today by visiting our [PK Domain Registration page](/pk-domain-registration).",
    },
    {
      question:
        "What payment methods are accepted for .pk domain registration?",
      answer:
        "Host Welcome accepts multiple **secure payment methods** including credit/debit cards, JazzCash, EasyPaisa, PayPal, and bank transfers. Choose your preferred payment option and buy your .pk domain easily. Visit our [Billing Information page](/billing-information) for details.",
    },
    {
      question: "Can I renew my .pk domain with Host Welcome?",
      answer:
        "Yes, we offer **easy .pk domain renewal** before expiry. You will receive renewal reminders, ensuring your domain remains active. Renew your domain today through your [Host Welcome account](/account) or visit our [PK Domain Renewal page](/pk-domain-renewal).",
    },
  ];

  const handleRenewClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const domains = [
    { extension: "pk", price: "3200/2yr" },

    { extension: "com.pk", price: "3200/2yr" },
    { extension: "org.pk", price: "3200/2yr" },
    { extension: "net.pk", price: "3200/2yr" },

    { extension: "biz.pk", price: "3200/2yr" },
    { extension: "web.pk", price: "3200/2yr" },

    { extension: "edu.pk", price: "3200/2yr" },
  ];
  return (
    <>
      <Helmet>
        <title>
          Register .PK Domain in Pakistan - Cheap & Instant | Host Welcome
        </title>
        <meta
          name="description"
          content="Get your .PK domain registered instantly at the lowest price in Pakistan. Secure your brand with fast activation, DNS management, and 24/7 support. Ideal for businesses and individuals looking to establish a strong online presence in Pakistan."
        />
      </Helmet>
      <header>
        <div className="domain-banner d-flex">
          <div className="domain-ban-left">
            <h6> PKDomain Registration</h6>
            <h1>Register your PK domain</h1>
            <span className="d-flex">
              Get a .pk doamain in just<p>&nbsp;&nbsp;3200/2yr*</p>
            </span>
            <div className="domainpagetldswrapper">
              <DomainSearch />
            </div>

            <span className="d-flex">
              Want to renew your PK domain?<p>&nbsp;Renew Now.</p>
            </span>
          </div>
        </div>
                  <div className='number-bar'>
                  <p className='pe-4'>Have any Question?</p>
                  <p className='pe-4'>We have Precise answer. </p>
                  <IoMdContacts />
                  
                  <p>+92 3242163697</p>
                  </div>
      </header>

      <section>
        <div className="domains-container">
          <h2>Get Yourself the Right Top Level Domain Name</h2>

          <div className="domains-grid">
            {domains.map((domain) => (
              <div key={domain.extension} className="domain-item">
                <h3>{domain.extension}</h3>
                <p className="d-flex">
                  Get in just <span>{domain.price}</span>
                </p>
                <button>Search Now</button>
              </div>
            ))}
          </div>
        </div>
        <div className="edu-domain-requirements">
          <h2>.edu.pk Domain Registration Requirements in Pakistan</h2>

          <p className="intro">
            If you want to **register a .edu.pk domain** for your educational
            institute in Pakistan, you must meet the **PKNIC registration
            policy**. The **domain registrant must be a recognized educational
            institute** with active student enrollment, and the **domain name
            must reflect the institute’s name**.
          </p>

          <p className="inactive-notice">
            Please note that all **.edu.pk domain registrations remain inactive
            initially** and require verification before activation.
          </p>

          <h3>How to Activate Your .edu.pk Domain?</h3>
          <p>
            To activate your **.edu.pk domain**, submit the following documents:
          </p>

          <ul className="requirements-list">
            <li>
              **An authorization letter** on the official **letterhead** of the
              institute, signed by the **Principal or Head** (must be an
              original, not a photocopy or digital print).
            </li>
            <li>
              **Proof of Registration** – A valid registration certificate from
              a **government education authority** such as HEC, IBCC, or local
              education boards.
            </li>
            <li>
              **CNIC Copy** – A scanned copy of the **National Identity Card
              (CNIC)** of the authorized signatory.
            </li>
          </ul>

          <h3>Where to Submit .edu.pk Domain Documents?</h3>
          <p className="address">
            Send all required documents to the official **PKNIC Collection
            Center**:
          </p>

          <address className="address-details">
            PKNIC Collection Office <br />
            Manzoor Colony Sector I<br />
            Karachi, Pakistan
          </address>

          <p className="activation-note">
            **Your .edu.pk domain will be activated upon successful document
            verification.** Make sure your details are accurate to avoid delays
            in the **PKNIC domain activation process**.
          </p>

          <h3>Why Register a .edu.pk Domain?</h3>
          <p>
            A **.edu.pk domain** is the official **Pakistan education domain
            extension**, helping schools, colleges, and universities establish
            an **authentic online presence**. Register today and secure your
            institution’s identity with a trusted **PKNIC .edu.pk domain**.
          </p>

          <p>
            For more details or to check **.edu.pk domain availability**, visit
            our
            <Link to="/pk-domain-registration">
              .edu.pk Domain Registration page
            </Link>
            .
          </p>
        </div>

        <div className="domain-details">
          <section className="pk-domain-renewal">
            <div className="content-container">
              <div className="text-content">
                <h1 className="section-title">
                  .PK Domain Renewal Pakistan | Affordable .PK Domain Extension
                  Renewal Services
                </h1>
                <p>
                  Renew your .pk domain name quickly and securely with
                  Pakistan's trusted PKNIC authorized partner. Whether you need
                  to renew .pk domains registered with another provider or
                  maintain your existing Pakistan country-code domain, we offer
                  the most competitive{" "}
                  <strong>.pk domain renewal prices in Pakistan</strong>. Enjoy
                  hassle-free domain renewal with 24/7 support, automatic
                  renewal reminders, and complete DNS management. As official
                  partners of the .pk domain registry, we guarantee authentic
                  domain renewal services with instant activation. Protect your
                  online identity in Pakistan - renew your .pk domain today and
                  maintain your website's local search engine visibility.
                </p>
                <button
                  className="renew-btn"
                  onClick={handleRenewClick}
                  aria-label="Renew .PK Domain Now - Pakistan's Trusted Domain Service"
                >
                  Renew .PK Domain - Best Prices in Pakistan
                </button>
              </div>
              <div className="image-content">
                <img
                  src="https://www.ehostpk.com/wp-content/uploads/2022/07/renew-1024x763.png"
                  alt="Renew .PK Domain in Pakistan - Instant Renewal Service for Pakistani Websites"
                  className="renew-image"
                  loading="lazy"
                />
              </div>
            </div>
          </section>
        </div>
        <DomainTips />

        <article className={styles.blogContainer}>
          <h1 className={styles.mainTitle}>
            How to Renew .PK Domains in Pakistan: Complete 2025 Guide
          </h1>

          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>Why Renew Your .PK Domain?</h2>
            <p className={styles.paragraph}>
              Renewing your{" "}
              <strong className={styles.highlight}>.pk domain name</strong> is
              crucial for maintaining your online presence in Pakistan.
            </p>
            <ul className={styles.list}>
              <li>✓ Boost local SEO rankings in Pakistani search engines</li>
              <li>✓ Maintain brand authority for Pakistani businesses</li>
              <li>✓ Avoid website downtime and email disruptions</li>
            </ul>
          </section>

          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>
              .PK Domain Renewal Process in Pakistan
            </h2>

            <div className={styles.step}>
              <h3 className={styles.stepTitle}>
                Step 1: Check Domain Expiry Date
              </h3>
              <p className={styles.paragraph}>
                Use our <strong>free .pk domain status checker</strong> to
                verify your expiration date.
              </p>
            </div>

            <div className={styles.step}>
              <h3 className={styles.stepTitle}>
                Step 2: Choose Renewal Duration
              </h3>
              <p className={styles.paragraph}>
                Renew your <strong>.pk domain for 1-10 years</strong> starting
                at Rs. 2,499/year.
              </p>
            </div>
          </section>

          <section className={`${styles.section} ${styles.faqSection}`}>
            <h2 className={styles.sectionTitle}>.PK Domain Renewal FAQs</h2>

            <div className={styles.faqItem}>
              <h3 className={styles.faqQuestion}>
                Q: Can I renew .pk domains after expiration?
              </h3>
              <p className={styles.faqAnswer}>
                A: Yes! Pakistan's PKNIC registry allows{" "}
                <strong>30-day grace period</strong> for renewal.
              </p>
            </div>
          </section>

          <section className={`${styles.section} ${styles.ctaSection}`}>
            <h2 className={styles.sectionTitle}>
              Renew Your .PK Domain Today!
            </h2>
            <button
              className={styles.ctaButton}
              onClick={handleRenewClick}
              aria-label="Renew .PK Domain Now"
            >
              Renew Now ➔
            </button>
          </section>
        </article>
                  <WhyChooseUs/>
                  <Topfeature/>
        <Questionmaries questions={questions} />
      </section>
    </>
  );
}
