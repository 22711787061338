import React from "react";
import { FlagIcon } from "react-flag-kit";
import { Globe } from "lucide-react";

const LocationsComponent = () => {
  const locations = [
    {
      region: "America",
      countries: [
        { city: "Toronto", country: "CA" },
        { city: "Mexico City", country: "MX" },
        { city: "Chicago", country: "US" },
        { city: "Atlanta", country: "US" },
        { city: "Seattle", country: "US" },
        { city: "Miami", country: "US" },
        { city: "Honolulu", country: "US" },
        { city: "Silicon Valley", country: "US" },
        { city: "New York", country: "US" },
        { city: "Los Angeles", country: "US" },
      ],
    },
    {
      region: "Europe",
      countries: [
        { city: "Stockholm", country: "SE" },
        { city: "London", country: "GB" },
        { city: "Amsterdam", country: "NL" },
        { city: "Frankfurt", country: "DE" },
        { city: "Paris", country: "FR" },
        { city: "Warsaw", country: "PL" },
      ],
    },
    {
      region: "Asia-Pacific",
      countries: [
        { city: "Sydney", country: "AU" },
        { city: "Melbourne", country: "AU" },
        { city: "Singapore", country: "SG" },
        { city: "Tokyo", country: "JP" },
        { city: "Seoul", country: "KR" },
      ],
    },
  ];

  return (
    <div className="location-wrapper container">
      <h2 className="title">
        <div><Globe size={28} /> 20+ Worldwide Locations</div>
       <p>Our Cloud vServer worldwide platform and experience the same great performance globally, in Pakistan</p>
      </h2>
     
      <div className="regions">
  
        {locations.map((region, index) => (
          <div key={index} className="region">
            <h3 className="region-title">{region.region}</h3>
            <ul className="location-list">
              {region.countries.map((location, idx) => (
                <li key={idx} className="location-item">
                  <FlagIcon code={location.country} size={16} />
                  {location.city}, {location.country}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LocationsComponent;
