import React from 'react'
import { FaBriefcase, FaCogs, FaGlobeAmericas, FaLaptopHouse, FaLock, FaRegHandshake, FaServer, FaShieldAlt, FaTools } from "react-icons/fa";

import { IoSpeedometerSharp } from "react-icons/io5";

import { SiMinutemailer } from "react-icons/si";

import { LuPanelTop } from "react-icons/lu";
import { MdEmail, MdSecurity } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";


import { IoBriefcase } from "react-icons/io5";
import { IoMdContacts, IoMdSpeedometer } from "react-icons/io";
import HostingCard from '../components/HostingCard';

import Topfeature from '../components/Topfeature';
import DemoBox from '../components/DemonBox';
import CustomerReviews from '../components/CustomerReviews';
import QASection from '../components/Questionmaries';

import { Helmet } from 'react-helmet';
import HomePricingComparision from '../components/HomePricingComparision';
import WhyChooseUs from '../components/WhyChooseUs';
import VPSFeatures from '../components/VPSFeatures';
import { FaRegHardDrive } from 'react-icons/fa6';
export default function Businesshosting() {
    const hostingGrid = [
      {
        id: 1,
        title: "Business Hosting",
        badge: "NVMe SSD",
        icon: <FaBriefcase />, 
        text: "High-performance hosting package for websites with high traffic. Optimized for WordPress, Magento, and other CMS platforms.",
        link: "/business-hosting",
      },
      {
        id: 2,
        title: "Reseller Hosting",
        badge: "NVMe SSD",
        icon: <FaCogs/>, 
        text: "Start your own hosting business with our Reseller Hosting. Complete control with WHM and cPanel.",
        link: "/reseller-hosting",
      },

    ];
    const features = [
      {
        id: 1,
        title: "Scalable Resources",
        description:
          "Our business hosting plans provide flexible resource scaling to grow with your business needs. Whether you need more storage, bandwidth, or processing power, you can scale your hosting plan without any interruptions.",
        icon: <FaLaptopHouse />,
      },
      {
        id: 2,
        title: "High-Speed Performance",
        description:
          "Business hosting is optimized for fast loading speeds to ensure your website performs well even during peak traffic. With dedicated resources and SSD storage, you can expect quicker load times and a seamless user experience.",
        icon: <IoMdSpeedometer />,
      },
      {
        id: 3,
        title: "Reliable Uptime Guarantee",
        description:
          "We offer a 99.9% uptime guarantee, ensuring that your website is always available to your customers. Our reliable infrastructure provides robust protection against downtime, keeping your business online 24/7.",
        icon: <FaShieldAlt />,
      },
      {
        id: 4,
        title: "Managed Services",
        description:
          "Our business hosting includes fully managed services. We take care of server management, updates, and security patches so you can focus on running your business while we handle the technical side.",
        icon: <FaTools />,
      },
      {
        id: 5,
        title: "Free SSL Certificates",
        description:
          "Ensure your customers’ data is secure with free SSL certificates. Protect sensitive information with encryption, gain the trust of your visitors, and improve your website's SEO ranking.",
        icon: <FaLock />,
      },
      {
        id: 6,
        title: "Enterprise-Grade Security",
        description:
          "Our hosting platform provides enterprise-grade security features such as firewalls, DDoS protection, and automatic backups to safeguard your website from potential threats and data loss.",
        icon: <FaShieldAlt />,
      },
      {
        id: 7,
        title: "24/7 Support",
        description:
          "Our expert support team is available 24/7 to assist with any hosting-related issues or technical concerns. We’re always here to help you ensure your business operates smoothly.",
        icon: <FaRegHandshake />,
      },
      {
        id: 8,
        title: "Customizable Hosting Plans",
        description:
          "Business hosting plans are customizable based on your specific requirements. Tailor your hosting plan to include the resources you need for business-critical applications and web solutions.",
        icon: <FaLaptopHouse />,
      },
      {
        id: 9,
        title: "Email Hosting Included",
        description:
          "Enhance your business presence with professional email hosting, using your domain name for email addresses. This ensures a cohesive brand identity and allows for easy communication with clients.",
        icon: <FaRegHandshake />,
      },
      {
        id: 10,
        title: "Automatic Backups",
        description:
          "We provide automated daily backups to protect your website data. In case of unexpected events, you can restore your site quickly to avoid any loss of important business information.",
        icon: <FaTools />,
      },
    ];

  const questions = [
    { 
      question: "Why choose Host Welcome for business web hosting solutions?",
      answer: "Host Welcome provides enterprise-grade business hosting with 99.9% uptime, scalable resources, and advanced security features. Our optimized hosting environment ensures faster page loads, improved SEO performance, and dedicated support - perfect for growing businesses. Explore our [Business Hosting Plans](/business-hosting) to boost your online presence."
    },
    { 
      question: "What business hosting features does Host Welcome offer?",
      answer: "Our business hosting packages include premium features: <ul><li>Free SSL certificates for secure transactions</li><li>Daily malware scanning & DDoS protection</li><li>SSD storage with automatic backups</li><li>Dedicated IP addresses</li><li>24/7 priority business support</li></ul> View all features on our [Business Hosting Features](/business-features) page."
    },
    { 
      question: "How does Host Welcome ensure website security for businesses?",
      answer: "Host Welcome's business hosting includes enterprise security: <ul><li>Web Application Firewall (WAF)</li><li>Automated vulnerability patching</li><li>Malware removal guarantee</li><li>Two-factor authentication</li><li>PCI compliance for eCommerce</li></ul> Protect your business with our [Advanced Security Solutions](/business-security)."
    },
    { 
      question: "Can I scale my business hosting with Host Welcome as my company grows?",
      answer: "Yes! Host Welcome offers seamless scalability with our flexible business hosting solutions: <ul><li>Easy upgrades from shared to cloud hosting</li><li>Instant resource scaling (CPU/RAM)</li><li>Multi-server management</li><li>Custom hosting configurations</li></ul> Learn about our [Scalable Business Hosting](/scalable-hosting) options."
    },
    { 
      question: "Does Host Welcome offer managed WordPress hosting for businesses?",
      answer: "Our managed WordPress hosting includes: <ul><li>Auto-core updates</li><li>Staging environments</li><li>Plugin vulnerability monitoring</li><li>Built-in caching</li><li>SEO optimization tools</li></ul> Perfect for business websites - see [WordPress Business Hosting](/wordpress-business)."
    },
    { 
      question: "What enterprise hosting solutions does Host Welcome provide?",
      answer: "For large businesses, we offer: <ul><li>Dedicated servers with full root access</li><li>Private cloud hosting clusters</li><li>Custom CDN configurations</li><li>Load balancing solutions</li><li>White-glove migration services</li></ul> Explore [Enterprise Hosting Solutions](/enterprise-hosting)."
    },
    { 
      question: "How does Host Welcome optimize hosting for eCommerce businesses?",
      answer: "Our eCommerce hosting features: <ul><li>WooCommerce/Magento optimization</li><li>Instant SSL certificates</li><li>PCI-DSS compliance</li><li>Database acceleration</li><li>Fraud prevention tools</li></ul> Boost your online store with [eCommerce Hosting](/ecommerce-hosting)."
    },
    { 
      question: "What compliance standards does Host Welcome business hosting meet?",
      answer: "We comply with: <ul><li>GDPR data protection</li><li>ISO 27001 security</li><li>HIPAA-ready configurations</li><li>SOC 2 Type II certified data centers</li></ul> Ensure compliance with our [Enterprise Hosting Solutions](/compliance-hosting)."
    },
    { 
      question: "Does Host Welcome provide email hosting for businesses?",
      answer: "Yes! Our business email hosting includes: <ul><li>Custom domain addresses</li><li>50GB+ mailbox storage</li><li>Spam/virus protection</li><li>Mobile sync & calendar integration</li><li>Email continuity solutions</li></ul> Get professional email with [Business Email Hosting](/business-email)."
    },
    { 
      question: "What support options come with business hosting plans?",
      answer: "Host Welcome offers 24/7/365 premium support: <ul><li>Dedicated account manager</li><li>15-minute response SLA</li><li>Phone/chat/email support</li><li>Technical onboarding assistance</li></ul> Experience our [Business Support Services](/business-support)."
    }
];

  const HomePlans = [
    {
      name: "BASIC",
      price: "2",
      cents: "99",
      color: "slate",
      features: [
        { icon: <FaServer />, text: "1 Website" },
        { icon: <FaRegHardDrive />, text: "10 GB SSD Storage" },
        { icon: <FaDatabase />, text: "5 MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL Certificate" },
        { icon: <MdEmail />, text: "10 Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel Control Panel" },
        { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
      ],
    },
    {
      name: "STANDARD",
      price: "4",
      cents: "99",
      color: "sky",
      bestSeller: true,
      features: [
        { icon: <FaServer />, text: "Unlimited Websites" },
        { icon: <FaRegHardDrive />, text: "50 GB SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL Certificate" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel Control Panel" },
        { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
        { icon: <LuPanelTop />, text: "Daily Backups" },
      ],
    },
    {
      name: "BUSINESS",
      price: "7",
      cents: "99",
      color: "green",
      features: [
        { icon: <FaServer />, text: "Unlimited Websites" },
        { icon: <FaRegHardDrive />, text: "100 GB SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL & SiteLock Security" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel + CloudLinux" },
        { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
        { icon: <LuPanelTop />, text: "Daily Backups + Free CDN" },
      ],
    },
    {
      name: "PRO",
      price: "12",
      cents: "99",
      color: "orange",
      features: [
        { icon: <FaServer />, text: "Unlimited Websites" },
        { icon: <FaRegHardDrive />, text: "Unlimited SSD Storage" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
        { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
        { icon: <FaLock />, text: "Free SSL, SiteLock & DDoS Protection" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "cPanel + CloudLinux + LiteSpeed" },
        { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
        { icon: <LuPanelTop />, text: "Daily Backups + Free CDN + Priority Support" },
        { icon: <MdSecurity />, text: "Malware Scan & Removal" },
      ],
    },
  ];
  
  return (
    <>
     <Helmet>
        <title>High-Performance Business Hosting in Pakistan | Host Welcome</title>
        <meta 
          name="description" 
          content="Get premium business hosting in Pakistan with high-speed SSD storage, dedicated resources, free SSL, and 24/7 priority support. Perfect for enterprises and high-traffic websites needing top performance and reliability." 
        />
      </Helmet>
    <div className='business-hosting-banner
    '>
        <header>
        <div className='shared-head-banner d-flex
        '>
            <span><IoBriefcase /><p>
            Managed Business Web Hosting </p></span>
        </div>
        </header>
        <div className='number-bar'>
        <p className='pe-4'>Have any Question?</p>
        <p className='pe-4'>We have Precise answer. </p>
        <IoMdContacts />
        
        <p>+92 3242163697</p>
        </div>
    </div>
    <section className='otherhostinggrid'><HostingCard hostingGrid={hostingGrid}/></section>
  
<section className='businesshostingplans'>
    <h2>Plan and Price</h2>
    <p>Here's the comparison chart to pick the correct hosting plan based on your needs and budget.</p>
    <HomePricingComparision HomePlans={HomePlans}/>
</section>
 <VPSFeatures title="Why Choose Business Hosting" features={features}/>
   <DemoBox/>
   <Topfeature/>
   <WhyChooseUs/>
   <CustomerReviews/>
   <QASection questions={questions}/>
    </>
  )
}
