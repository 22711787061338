import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaWhatsapp } from 'react-icons/fa'; // FontAwesome icons



const SocialIcons = () => {
  return (
    <div className='large-social-icons'>
     <div className="social-icons">
     <a href="https://wa.me/923242163697" target="_blank" rel="noopener noreferrer" className="icon whatsapp">
  <FaWhatsapp />
</a>

<a href="https://www.facebook.com/profile.php?id=61562582806028&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="icon facebook">
  <FaFacebook />
</a>

<a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="icon twitter">
  <FaTwitter />
</a>

<a href="https://www.instagram.com/waqas_ashraf_99" target="_blank" rel="noopener noreferrer" className="icon instagram">
  <FaInstagram />
</a>

    </div>
    </div>
  );
};

export default SocialIcons;