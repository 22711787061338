import React from "react";

import { Link } from "react-router-dom"; 

const HostingGrid = ({ hostingGrid }) => {
  return (
    <div className="hosting-grid">
      {hostingGrid.map((plan) => (
        <div key={plan.id} className="packagewrapperhosting">
          <div className="badge">{plan.badge}</div>
          <div className="icon-container">{plan.icon}</div>
          <h3 className="title">{plan.title}</h3> 
          <p className="description">{plan.text}</p>
          <Link to={plan.link} className="view-plans">View Plans</Link>  
        </div>
      ))}
    </div>
  );
};

export default HostingGrid;
