import React from 'react'
import DomainSearch from '../components/DomainSearch'
import Questionmaries from '../components/Questionmaries';
import DomainTips from '../components/DomainTips';
import {  useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import WhyChooseUs from '../components/WhyChooseUs';
import CustomerReviews from '../components/CustomerReviews';
import Topfeature from '../components/Topfeature';
import { IoMdContacts } from 'react-icons/io';


export default function Domain() {

  const handlescrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Adds smooth scrolling
    });
  };

  const questions= [
    {
      question: "How do I register a domain name with Host Welcome?",
      answer: "Registering a domain name with Host Welcome is easy and fast. Simply follow these steps: 1. Use our [Domain Search Tool](/domain-registration) to find the perfect domain name for your website. 2. Check availability – We offer a wide range of popular domain extensions like .com, .net, .org, and more. 3. Complete the registration process by adding your domain to the cart and providing your billing details. 4. Once confirmed, your domain is officially registered and ready for use! Whether you’re looking to buy a domain for a personal website, business, or blog, Host Welcome makes the domain registration process simple and affordable. Learn more on our [Domain Registration page](/domain-registration)."
    },
    {
      question: "Is domain registration with Host Welcome easy and secure?",
      answer: "Yes, domain registration with Host Welcome is both easy and secure. We provide a user-friendly interface to search, select, and register your domain quickly. Our secure payment options ensure that your transaction is safe. We also offer domain privacy protection to keep your personal information secure. Start your journey by visiting our [Domain Registration page](/domain-registration) to see how simple and safe it is to buy a domain with Host Welcome."
    },
    {
      question: "What domain extensions are available for registration at Host Welcome?",
      answer: "At Host Welcome, we offer a wide variety of domain extensions to suit your needs. Choose from popular options like .com, .net, and .org, or explore newer domain extensions such as .tech, .co, .store, and country-specific extensions like .pk. Our [Domain Registration page](/domain-registration) provides detailed information about all available domain extensions, helping you pick the perfect one for your website."
    },
    {
      question: "How can I transfer my domain to Host Welcome?",
      answer: "Transferring your domain to Host Welcome is quick and easy. To begin, simply follow the steps outlined on our [Domain Transfer page](/domain-transfer). Our team will assist you throughout the process to ensure your domain transfer is seamless and hassle-free."
    },
    {
      question: "Is domain privacy protection available with Host Welcome?",
      answer: "Yes, we offer domain privacy protection as an additional service to safeguard your personal contact details. This service prevents your private information from being publicly listed in the WHOIS database. You can enable domain privacy protection during registration or at any time through your [Host Welcome account](/account)."
    },
    {
      question: "What are the benefits of registering a domain with Host Welcome?",
      answer: "When you register a domain with Host Welcome, you benefit from reliable customer support, affordable pricing, and a wide selection of domain extensions. Additionally, we provide enhanced security options, including SSL certificates and domain privacy protection. Visit our [Domain Registration page](/domain-registration) to explore all the benefits of choosing Host Welcome for your domain registration needs."
    },
    {
      question: "Can I register multiple domains with Host Welcome?",
      answer: "Yes, you can easily register multiple domains with Host Welcome. Whether you're launching several websites or expanding your business's online presence, we offer bulk domain registration and management tools. Learn more on our [Domain Registration page](/domain-registration) and start registering your domains today!"
    },
    {
      question: "How long does it take to register a domain with Host Welcome?",
      answer: "Domain registration with Host Welcome is completed in just a few minutes. Once you've chosen your domain and completed the payment, the domain is instantly registered and ready for use. Visit our [Domain Registration page](/domain-registration) for more details."
    },
    {
      question: "What payment methods can I use to register a domain with Host Welcome?",
      answer: "Host Welcome accepts various payment methods for domain registration, including credit/debit cards, PayPal, and bank transfers. For more details, visit our [Billing Information page](/billing-information)."
    },
    {
      question: "Can I renew my domain with Host Welcome?",
      answer: "Yes, you can easily renew your domain with Host Welcome before it expires. We send renewal reminders to ensure your domain remains active. You can renew your domain directly from your [Host Welcome account](/account)."
    }
  ];
  

  

  const domains = [
    { extension: "com", price: "$13/yr" },
    { extension: "net", price: "$15/yr" },
    { extension: "org", price: "$15/yr" },
    { extension: "pk", price: "PKR 3200/2yr" },
    { extension: "com.pk", price: "PKR 3200/2yr" },
    { extension: "info", price: "$6.5/yr" },
    { extension: "biz", price: "$30/yr" },
    { extension: "uk", price: "$10/yr" },
    { extension: "co.uk", price: "$10/yr" },
    { extension: "us", price: "$10/yr" },
    { extension: "xyz", price: "$15/yr" },
    { extension: "me", price: "$25/yr" },
    { extension: "de", price: "$11/yr" },
  ];
  return (
<>
<Helmet>
        <title>Cheap Domain Registration in Pakistan | Host Welcome</title>
        <meta 
          name="description" 
          content="Register your .PK, .COM, .NET, and other domains at the lowest prices in Pakistan. Instant activation, DNS management, and 24/7 support available. Secure your brand today!" 
        />
      </Helmet>

 <header>
  <div className='domain-banner d-flex'>
   
    <div className='domain-ban-left'>
       <h6>Domain Registration</h6>
       <h1>Grab Your Doman Now</h1>
       <span className='d-flex' >Get a .com doamaim in just<p>&nbsp;&nbsp;$15/yr*</p></span>
       <div className='domainpagetldswrapper'>
       <DomainSearch/>
       </div>
       
       <span className='d-flex'>Already have a domain?<p>&nbsp;Transfer a domain to us.</p></span>
    </div>
 
  </div>
          <div className='number-bar'>
          <p className='pe-4'>Have any Question?</p>
          <p className='pe-4'>We have Precise answer. </p>
          <IoMdContacts />
          
          <p>+92 3242163697</p>
          </div>
 </header>


 <section>
 <div className="domains-container container">
      <h2>Get Yourself the Right Top Level Domain Name</h2>
  
      <div className="domains-grid">
        {domains.map((domain) => (
          <div key={domain.extension} className="domain-item">
            <h3>{domain.extension}</h3>
            <p className="d-flex">Get in just <span>{domain.price}</span></p>
            <button>Search Now</button>
          </div>
        ))}
      </div>
    </div>
    <DomainTips/>

    <div className="domain-promo">
      <h2>Find Your Domain Name Today!</h2>
      <p className="price">.com in just <span>$15</span></p>
      <button className="get-started-btn" onClick={handlescrolltop}>Get Started</button>
    </div>

     <WhyChooseUs/>
     <Topfeature/>
     <CustomerReviews/>
    <Questionmaries questions={questions}/>
  

 </section>


</>
  )
}
