import React from 'react'
import QASection from '../components/Questionmaries';
import HomePricingComparision from '../components/HomePricingComparision';
import { MdEmail, MdSecurity } from 'react-icons/md';
import { LuPanelTop } from 'react-icons/lu';
import { FaCentos, FaDatabase, FaGlobeAmericas, FaLinux, FaLock, FaServer, FaUbuntu } from 'react-icons/fa';
import { SiMinutemailer } from 'react-icons/si';
import { IoSpeedometerSharp } from 'react-icons/io5';
import { FaDebian, FaRegHardDrive } from 'react-icons/fa6';
import VPSFeatures from '../components/VPSFeatures';
import LocationsComponent from '../components/LocationComponent';
import { IoMdContacts } from 'react-icons/io';
import { Helmet } from 'react-helmet';
import WhyChooseUs from '../components/WhyChooseUs';
import Topfeature from '../components/Topfeature';
import { ServerCog } from 'lucide-react';
import { BiSolidTerminal } from 'react-icons/bi';

export default function LinuxVserver() {
  const questions = [
    { 
      question: "What is Linux server hosting and how does Host Welcome support it?", 
      answer: "Linux server hosting is a reliable hosting solution powered by the Linux operating system, offering excellent performance, security, and flexibility. Host Welcome provides top-tier Linux server hosting, with full root access, customizable plans, and support for various applications such as PHP, MySQL, and more, making it ideal for businesses that prefer open-source technologies."
    },
    { 
      question: "What are the key benefits of Linux server hosting with Host Welcome?", 
      answer: "Linux server hosting with Host Welcome offers enhanced performance, security, and flexibility. You get root access to your server, complete control over software configurations, and the ability to install and manage custom applications. Linux hosting is also cost-effective and supports a wide variety of open-source software, making it a popular choice for developers and tech-savvy businesses."
    },
    { 
      question: "How does Host Welcome’s Linux server hosting differ from other hosting options?", 
      answer: "Host Welcome’s Linux server hosting offers a more cost-effective and flexible alternative to Windows hosting. It supports open-source technologies, such as Apache, PHP, and MySQL, and is ideal for developers and businesses that prefer the Linux environment. Unlike Windows servers, Linux servers are open-source and more customizable, allowing you to have full control over your server and applications."
    },
    { 
      question: "What Linux server hosting plans does Host Welcome offer?", 
      answer: "Host Welcome offers a range of Linux server hosting plans designed to suit businesses of all sizes. Whether you're running a small website or need resources for complex applications, we have flexible plans that offer scalable performance. Check out our [Linux Server Hosting Plans](/linux-server-hosting) for more details."
    },
    { 
      question: "Is Linux server hosting suitable for web developers?", 
      answer: "Yes, Linux server hosting is highly suitable for web developers. It supports popular development tools and programming languages like PHP, Python, and Ruby. With full root access and the ability to install custom software, developers have the flexibility to configure their servers to meet their specific project requirements."
    },
    { 
      question: "How does Host Welcome ensure the security of Linux server hosting?", 
      answer: "Host Welcome provides a secure Linux server hosting environment by implementing firewalls, DDoS protection, malware scanning, and regular security patches. Additionally, we offer full root access, so you have complete control over your server's security settings. Our monitoring systems help protect your server from potential threats, ensuring your website and data remain secure."
    },
    { 
      question: "Can I customize my Linux server hosting plan with Host Welcome?", 
      answer: "Yes, Host Welcome allows you to fully customize your Linux server hosting plan. You can select the server resources, such as CPU, RAM, storage, and bandwidth, according to your business needs. With full root access, you have the freedom to install and configure any software or services required for your applications."
    },
    { 
      question: "What kind of customer support does Host Welcome provide for Linux server hosting?", 
      answer: "Host Welcome provides 24/7 customer support for all Linux server hosting clients. Whether you need help with server configuration, security, or software installations, our expert team is available via live chat, email, or phone to assist with your technical needs."
    },
    { 
      question: "Can I host multiple websites on a Linux server hosting plan with Host Welcome?", 
      answer: "Yes, with Host Welcome’s Linux server hosting, you can host multiple websites on the same server. This is especially useful for businesses or developers managing multiple projects. You have the flexibility to allocate resources and configure hosting environments for each website individually."
    },
    { 
      question: "Does Host Welcome offer a money-back guarantee for Linux server hosting?", 
      answer: "Yes, Host Welcome offers a 30-day money-back guarantee on all Linux server hosting plans. If you're not satisfied with our services, you can request a full refund within the first 30 days. For more information, check our [Money-Back Guarantee page](/money-back-guarantee)."
    },
    { 
      question: "Can I run MySQL and other databases on Host Welcome’s Linux server hosting?", 
      answer: "Yes, Host Welcome’s Linux server hosting supports MySQL, PostgreSQL, and other popular databases. With full control over your server, you can easily configure and manage databases to suit your specific application needs."
    }
  ];
   const HomePlans = [
      {
        name: "BASIC",
        price: "2",
        cents: "99",
        color: "slate",
        features: [
          { icon: <FaServer />, text: "1 Website" },
          { icon: <FaRegHardDrive />, text: "10 GB SSD Storage" },
          { icon: <FaDatabase />, text: "5 MySQL Databases" },
          { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
          { icon: <FaLock />, text: "Free SSL Certificate" },
          { icon: <MdEmail />, text: "10 Email Accounts" },
          { icon: <SiMinutemailer />, text: "cPanel Control Panel" },
          { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
        ],
      },
      {
        name: "STANDARD",
        price: "4",
        cents: "99",
        color: "sky",
        bestSeller: true,
        features: [
          { icon: <FaServer />, text: "Unlimited Websites" },
          { icon: <FaRegHardDrive />, text: "50 GB SSD Storage" },
          { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
          { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
          { icon: <FaLock />, text: "Free SSL Certificate" },
          { icon: <MdEmail />, text: "Unlimited Email Accounts" },
          { icon: <SiMinutemailer />, text: "cPanel Control Panel" },
          { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
          { icon: <LuPanelTop />, text: "Daily Backups" },
        ],
      },
      {
        name: "BUSINESS",
        price: "7",
        cents: "99",
        color: "green",
        features: [
          { icon: <FaServer />, text: "Unlimited Websites" },
          { icon: <FaRegHardDrive />, text: "100 GB SSD Storage" },
          { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
          { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
          { icon: <FaLock />, text: "Free SSL & SiteLock Security" },
          { icon: <MdEmail />, text: "Unlimited Email Accounts" },
          { icon: <SiMinutemailer />, text: "cPanel + CloudLinux" },
          { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
          { icon: <LuPanelTop />, text: "Daily Backups + Free CDN" },
        ],
      },
      {
        name: "PRO",
        price: "12",
        cents: "99",
        color: "orange",
        features: [
          { icon: <FaServer />, text: "Unlimited Websites" },
          { icon: <FaRegHardDrive />, text: "Unlimited SSD Storage" },
          { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
          { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
          { icon: <FaLock />, text: "Free SSL, SiteLock & DDoS Protection" },
          { icon: <MdEmail />, text: "Unlimited Email Accounts" },
          { icon: <SiMinutemailer />, text: "cPanel + CloudLinux + LiteSpeed" },
          { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
          { icon: <LuPanelTop />, text: "Daily Backups + Free CDN + Priority Support" },
          { icon: <MdSecurity/>, text: "Malware Scan & Removal" },
        ],
      },
    ];

    const features = [
      {
        id: 1,
        title: "Ubuntu VPS",
        description:
          "Ubuntu VPS hosting provides a secure and stable environment with full root access. Ideal for developers, you can install software like Apache, MySQL, and Docker with ease. Ubuntu's large community ensures continuous updates and support.",
        icon: <FaUbuntu />,
      },
      {
        id: 2,
        title: "CentOS VPS",
        description:
          "CentOS VPS hosting is built for enterprise-level stability and security. With long-term support and compatibility with cPanel & WHM, it’s perfect for businesses and web hosting providers.",
        icon: <FaCentos />,
      },
      {
        id: 3,
        title: "Debian VPS",
        description:
          "Debian VPS hosting is known for its rock-solid security and lightweight performance. Best suited for advanced users who require a minimal yet powerful server environment.",
        icon: <FaDebian />,
      },
      {
        id: 4,
        title: "Command Line Control",
        description:
          "All Linux VPS plans include full SSH root access, allowing you to configure your server through the command line. Install and manage software efficiently with terminal commands.",
        icon: <BiSolidTerminal />,
      },
      {
        id: 5,
        title: "Scalability & Performance",
        description:
          "Our Linux VPS solutions come with SSD storage, scalable resources, and a 99.9% uptime guarantee. Easily upgrade your server as your project grows.",
        icon: <ServerCog />,
      },
    ];
    
  return (
    
    <div>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Linux Servers | Host Welcome</title>
        <meta name="description" content="Deploy powerful and secure Linux servers for your hosting, development, and enterprise needs with full control and flexibility." />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* Open Graph and Twitter Card meta tags for better visibility on social media */}
        <meta property="og:title" content="Linux Servers | Host Welcome" />
        <meta property="og:description" content="Unlock the power of Linux with fast, secure, and highly customizable Linux servers tailored to your needs." />
        <meta property="og:image" content="URL_to_image_for_og_image" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Linux Servers | Host Welcome" />
        <meta name="twitter:description" content="Get powerful and secure Linux servers with full control, scalability, and flexibility." />
        <meta name="twitter:image" content="URL_to_image_for_twitter_card" />
      </Helmet>

          <div className='business-hosting-banner
          '>
              <header>
              <div className='shared-head-banner d-flex
              '>
                  <span><FaLinux /><p>
                  Linux VPS Server </p></span>
              </div>
              </header>
              <div className='number-bar'>
              <p className='pe-4'>Have any Question?</p>
              <p className='pe-4'>We have Precise answer. </p>
              <IoMdContacts />
              
              <p>+92 3242163697</p>
              </div>
          </div>
     

      <HomePricingComparision HomePlans={HomePlans}/>
      <VPSFeatures title="Why Choose Linux VPS"  features={features}/>
      <LocationsComponent/>
      <WhyChooseUs/>
      <Topfeature/>
       <QASection questions={questions}/>
    </div>
  )
}
