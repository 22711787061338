import React from "react";
import "./HomePricingComparision.css";

const HomePricingComparision = ({ HomePlans }) => {
  return (
    <div className="pricing-container">
      <div className="pricing-header">
        <p>Choose the Right Hosting Plan for You</p>
      </div>
      <div className="pricing-grid">
        {HomePlans.map((plan) => (
          <div
            key={plan.name}
            className={`pricing-card ${plan.color}`}
            style={{ borderColor: `var(--${plan.color})` }}
          >
            {plan.bestSeller && <div className="best-seller">BEST SELLER</div>}
            <div className="card-header-home">
              <h3>{plan.name}</h3>
              <div className="price">
                <span className="currency">$</span>
                <span className="amount">{plan.price}</span>
                <span className="cents">.{plan.cents}</span>
                <span className="period">/mo</span>
              </div>
            </div>
            <div className="card-content">
              <ul>
                {plan.features.map((feature, index) => (
                  <li key={index}>
                    {feature.icon}
                    {feature.text}
                  </li>
                ))}
              </ul>
            </div>
            <div className="card-footer">
              <button className="select-plan">
              <a href={plan.link} >
                Select Plan
              </a>
              </button>
          
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePricingComparision;
