import "./VPSFeatures.css";

const VPSFeatures = ({ title, features }) => {
  return (
    <div className="vps-container">
      <div className="vps-content">
        <h2 className="vps-title">{title}</h2> 
        <div className="features-grid">
          {features.map((feature) => (
            <div className="feature-item" key={feature.id}>
              <div className="icon-container">{feature.icon}</div>
              <div className="feature-content">
                <h3 className="feature-title">{feature.title}</h3>
                <p className="feature-description">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VPSFeatures;
