import React from 'react'
import { IoMdContacts } from "react-icons/io";
import { GrWindows } from "react-icons/gr";
// import ServerPlans from '../components/ServerPlans';
import LocationsComponent from '../components/LocationComponent';
import CustomerReviews from '../components/CustomerReviews';
import QASection from '../components/Questionmaries';
import { BiSolidShield } from "react-icons/bi";
import VPSFeatures from '../components/VPSFeatures';
import HomePricingComparision from '../components/HomePricingComparision';
import { MdEmail, MdSecurity } from 'react-icons/md';
import { LuPanelTop } from 'react-icons/lu';
import { FaDatabase, FaGlobeAmericas, FaLock, FaServer, FaWindows } from 'react-icons/fa';
import { SiMinutemailer } from 'react-icons/si';
import { IoSpeedometerSharp } from 'react-icons/io5';
import { FaRegHardDrive } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';
import WhyChooseUs from '../components/WhyChooseUs';
import Topfeature from '../components/Topfeature';
import { ServerCog } from 'lucide-react';

export default function WindowVserver() {
  const questions = [
    { 
      question: "What is Windows server hosting and how does Host Welcome support it?", 
      answer: "Windows server hosting is a hosting solution powered by Microsoft's Windows operating system, offering compatibility with a wide range of Windows-based applications and technologies. Host Welcome provides reliable Windows server hosting with full control, high performance, and a user-friendly environment, making it ideal for businesses that require specific Windows applications or environments."
    },
    { 
      question: "What are the key benefits of Windows server hosting with Host Welcome?", 
      answer: "Windows server hosting with Host Welcome offers enhanced compatibility with applications like ASP.NET, MSSQL, and Exchange, among others. You get full control over your hosting environment with remote desktop access, scalable resources, robust security features, and 24/7 support, ensuring a seamless experience for businesses using Windows-based solutions."
    },
    { 
      question: "How does Host Welcome’s Windows server hosting differ from other hosting options?", 
      answer: "Host Welcome’s Windows server hosting provides a Windows-based environment that allows businesses to run .NET applications, use Microsoft SQL Server, and rely on Active Directory for user management. This makes it a preferred choice for businesses that depend on Microsoft technologies. Unlike Linux hosting, Windows hosting gives you more flexibility with Windows-based software."
    },
    { 
      question: "What Windows server hosting plans does Host Welcome offer?", 
      answer: "Host Welcome offers a range of Windows server hosting plans designed for businesses of all sizes. These plans come with full administrative access, powerful servers, remote desktop access, and support for Windows-based applications. Visit our [Windows Server Hosting Plans](/windows-server-hosting) to explore all the available options."
    },
    { 
      question: "Is Windows server hosting suitable for enterprise applications?", 
      answer: "Yes, Windows server hosting is ideal for businesses running enterprise applications such as Microsoft SQL Server, SharePoint, or Exchange. Host Welcome’s high-performance servers and Windows environment provide the reliability and scalability required to run these mission-critical applications smoothly."
    },
    { 
      question: "How does Host Welcome ensure the security of Windows server hosting?", 
      answer: "Host Welcome takes the security of your Windows server hosting environment seriously. We offer robust security features such as firewalls, DDoS protection, SSL certificates, regular backups, and advanced malware scanning to protect your server and data from potential threats. Our support team is always available to assist with security management."
    },
    { 
      question: "Can I customize my Windows server hosting plan with Host Welcome?", 
      answer: "Yes, Host Welcome provides flexible Windows server hosting plans that can be customized to fit your business needs. You can scale resources such as CPU, RAM, and storage, and configure your server environment to run your specific applications or services. Whether you're running small applications or enterprise software, we can tailor a solution for you."
    },
    { 
      question: "What kind of customer support does Host Welcome offer for Windows server hosting?", 
      answer: "Host Welcome offers 24/7 customer support for all Windows server hosting clients. Our technical support team is ready to assist with any issues related to your server setup, application installation, performance optimization, or security concerns. You can reach our team through live chat, email, or phone."
    },
    { 
      question: "Is Windows server hosting compatible with .NET applications?", 
      answer: "Yes, Windows server hosting at Host Welcome is fully compatible with .NET applications. Whether you're running ASP.NET, ASP.NET Core, or any other .NET-based technologies, Host Welcome’s Windows servers are optimized to provide a seamless environment for these applications."
    },
    { 
      question: "Does Host Welcome offer a money-back guarantee for Windows server hosting?", 
      answer: "Yes, Host Welcome offers a 30-day money-back guarantee on all our Windows server hosting plans. If you're not satisfied with the service, you can request a full refund within the first 30 days. Learn more about our money-back guarantee on our [Money-Back Guarantee page](/money-back-guarantee)."
    },
    { 
      question: "Can I run Microsoft SQL Server on Host Welcome’s Windows server hosting?", 
      answer: "Yes, you can run Microsoft SQL Server on Host Welcome’s Windows server hosting. Our plans provide the required resources and environment to run SQL Server smoothly, allowing you to manage databases for your applications. We offer full administrative access to ensure you have complete control over your database environment."
    }
  ];
   const HomePlans = [
      {
        name: "BASIC",
        price: "2",
        cents: "99",
        color: "slate",
        features: [
          { icon: <FaServer />, text: "1 Website" },
          { icon: <FaRegHardDrive />, text: "10 GB SSD Storage" },
          { icon: <FaDatabase />, text: "5 MySQL Databases" },
          { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
          { icon: <FaLock />, text: "Free SSL Certificate" },
          { icon: <MdEmail />, text: "10 Email Accounts" },
          { icon: <SiMinutemailer />, text: "cPanel Control Panel" },
          { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
        ],
      },
      {
        name: "STANDARD",
        price: "4",
        cents: "99",
        color: "sky",
        bestSeller: true,
        features: [
          { icon: <FaServer />, text: "Unlimited Websites" },
          { icon: <FaRegHardDrive />, text: "50 GB SSD Storage" },
          { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
          { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
          { icon: <FaLock />, text: "Free SSL Certificate" },
          { icon: <MdEmail />, text: "Unlimited Email Accounts" },
          { icon: <SiMinutemailer />, text: "cPanel Control Panel" },
          { icon: <FaGlobeAmericas />, text: "99.9% Uptime Guarantee" },
          { icon: <LuPanelTop />, text: "Daily Backups" },
        ],
      },
      {
        name: "BUSINESS",
        price: "7",
        cents: "99",
        color: "green",
        features: [
          { icon: <FaServer />, text: "Unlimited Websites" },
          { icon: <FaRegHardDrive />, text: "100 GB SSD Storage" },
          { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
          { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
          { icon: <FaLock />, text: "Free SSL & SiteLock Security" },
          { icon: <MdEmail />, text: "Unlimited Email Accounts" },
          { icon: <SiMinutemailer />, text: "cPanel + CloudLinux" },
          { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
          { icon: <LuPanelTop />, text: "Daily Backups + Free CDN" },
        ],
      },
      {
        name: "PRO",
        price: "12",
        cents: "99",
        color: "orange",
        features: [
          { icon: <FaServer />, text: "Unlimited Websites" },
          { icon: <FaRegHardDrive />, text: "Unlimited SSD Storage" },
          { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
          { icon: <IoSpeedometerSharp />, text: "Unlimited Bandwidth" },
          { icon: <FaLock />, text: "Free SSL, SiteLock & DDoS Protection" },
          { icon: <MdEmail />, text: "Unlimited Email Accounts" },
          { icon: <SiMinutemailer />, text: "cPanel + CloudLinux + LiteSpeed" },
          { icon: <FaGlobeAmericas />, text: "99.99% Uptime Guarantee" },
          { icon: <LuPanelTop />, text: "Daily Backups + Free CDN + Priority Support" },
          { icon: <MdSecurity />, text: "Malware Scan & Removal" },
        ],
      },
    ];
    
    const features = [
      {
        id: 1,
        title: "FULL ADMIN ACCESS",
        description:
          "Our Windows VPS hosting provides full administrator (RDP) access, allowing you complete control over your server environment. Install any Windows applications, configure settings, and manage your hosting without restrictions. Whether you're running business applications, remote desktops, or gaming servers, our Windows VPS ensures maximum flexibility.",
        icon: <FaWindows />,
      },
      {
        id: 2,
        title: "SECURE & RELIABLE",
        description:
          "Security is a top priority for our Windows VPS hosting. With built-in firewall protection, regular updates, and DDoS protection, your server remains safe from cyber threats. We ensure 99.9% uptime with high-performance SSD storage, so your applications and websites run smoothly without interruptions.",
        icon: <BiSolidShield />,
      },
      {
        id: 3,
        title: "EASY MANAGEMENT",
        description:
          "Managing your Windows VPS is effortless with our intuitive control panel. Start, stop, or reboot your server in seconds. Need assistance? Our support team is available 24/7 to help with any technical issues. Plus, you can install popular Windows software like IIS, SQL Server, and ASP.NET with just a few clicks.",
        icon: <ServerCog />,
      },
    ];
  
  return (
<>
<Helmet>
        <meta charSet="utf-8" />
        <title>Windows Servers | Host Welcome</title>
        <meta name="description" content="Powerful and secure Windows servers designed for enterprise applications, data security, and seamless compatibility with Windows-based software." />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* Open Graph and Twitter Card meta tags for better visibility on social media */}
        <meta property="og:title" content="Windows Servers | Host Welcome" />
        <meta property="og:description" content="Leverage the full potential of Windows with high-performance Windows servers, ideal for hosting, enterprise solutions, and software compatibility." />
        <meta property="og:image" content="URL_to_image_for_og_image" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Windows Servers | Host Welcome" />
        <meta name="twitter:description" content="Secure and scalable Windows servers designed to run enterprise applications and provide seamless performance." />
        <meta name="twitter:image" content="URL_to_image_for_twitter_card" />
      </Helmet>
    <div className='windows-server-banner
    '>
        <header>
        <div className='shared-head-banner d-flex
        '>
            <span><GrWindows /><p>
            Windows VPS Server</p></span>
        </div>
        </header>
        <div className='number-bar'>
        <p className='pe-4'>Have any Question?</p>
        <p className='pe-4'>We have Precise answer. </p>
        <IoMdContacts />
        
        <p>+92 3242163697</p>
        </div>
        </div>
         <VPSFeatures title="Why Choose Window VPS"  features={features}/>
     <HomePricingComparision  HomePlans={HomePlans}/>
        <LocationsComponent/>
          <WhyChooseUs/>
          <Topfeature/>
        <CustomerReviews/>
        <QASection questions={questions}/>
</>
  )
}
