import React from 'react'

import { FaBolt, FaDatabase, FaGlobeAmericas, FaLock, FaServer } from "react-icons/fa";
import SharedHostingFeature from '../components/SharedHostingFeature';


import QASection from '../components/Questionmaries';
import DemoBox from '../components/DemonBox';
import { MdEmail, MdOutlineLocalPhone, MdSecurity, MdSupportAgent } from "react-icons/md";
import { FaGlobe, FaBusinessTime } from "react-icons/fa"
import { Helmet } from 'react-helmet';
import HomePricingComparision from '../components/HomePricingComparision';
import { LuPanelTop } from 'react-icons/lu';
import { SiMinutemailer } from 'react-icons/si';
import { IoSpeedometerSharp } from 'react-icons/io5';
import { FaRegHardDrive } from 'react-icons/fa6';
import WhyChooseUs from '../components/WhyChooseUs';
import Topfeature from '../components/Topfeature';
import { BiSolidDollarCircle } from 'react-icons/bi';
import VPSFeatures from '../components/VPSFeatures';
import { IoMdContacts } from 'react-icons/io';

export default function SharedHosting() {

  
  const HomePlans = [
    {
      name: "BASIC",
      price: "1",
      cents: "67",
      color: "slate",
      features: [
        { icon: <FaRegHardDrive />, text: "2gb Disk Space" },
        { icon: <IoSpeedometerSharp />, text: "20gb Monthly bandwidth" },
        { icon:<FaGlobeAmericas />, text: "10 Sub Domains" },
        { icon: <MdEmail />, text: "50 Email Accounts" },
        { icon: <SiMinutemailer />, text: "10 FTP Accounts" },
        { icon: <FaDatabase />, text: "05 MySQL Databases" },
       
    
      ],
       link:"https://hostwelcome.com/clients/cart.php?a=add&pid=2"
    },
    {
      name: "STANDARD",
      price: "2",
      cents: "50",
      color: "sky",
      bestSeller: true,
      features: [
        { icon: <FaRegHardDrive />, text: "5gb Disk Space" },
        { icon: <IoSpeedometerSharp />, text: "50gb Monthly bandwidth" },
        { icon:<FaGlobeAmericas />, text: "Unlimited Sub Domains" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "Unlimited FTP Accounts" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
       
      ],
       link:"https://hostwelcome.com/clients/cart.php?a=add&pid=3"
    },
    {
      name: "BUSINESS",
      price: "4",
      cents: "17",
      color: "green",
      features: [
        { icon: <FaRegHardDrive />, text: "10gb Disk Space" },
        { icon: <IoSpeedometerSharp />, text: "100gb Monthly bandwidth" },
        { icon:<FaGlobeAmericas />, text: "Unlimited Sub Domains" },
        { icon: <MdEmail />, text: "Unlimited Email Accounts" },
        { icon: <SiMinutemailer />, text: "Unlimited FTP Accounts" },
        { icon: <FaDatabase />, text: "Unlimited MySQL Databases" },
       
      ],
       link:"https://hostwelcome.com/clients/cart.php?a=add&pid=4"
    },

  ];
  

  
  const questions = [
    {
      question: "What is Host Welcome shared hosting and who is it best for?",
      answer: "Host Welcome's shared hosting is an affordable, high-performance web hosting solution perfect for small businesses, bloggers, and first-time website owners. Our optimized servers ensure fast page loading speeds, 99.9% uptime guarantee, and easy WordPress integration. Start your online journey with our beginner-friendly [Shared Hosting Plans](/shared-hosting) featuring one-click installations and 24/7 support."
    },
    {
      question: "How does Host Welcome ensure website security on shared hosting?",
      answer: "We prioritize security with enterprise-grade protection including free SSL certificates, daily malware scans, and advanced firewall protection. Our shared hosting environment features isolated accounts and automatic updates to keep your WordPress site or online store secure. Explore our [Security Features](/security) to learn how we protect your digital assets."
    },
    {
      question: "Can I upgrade my Host Welcome shared hosting plan as my website grows?",
      answer: "Absolutely! Host Welcome makes scaling effortless with seamless upgrades to higher-tier plans or VPS hosting. Our flexible shared hosting solutions grow with your traffic needs while maintaining optimal website performance. Visit our [Hosting Upgrades](/upgrades) page to explore scaling options."
    },
    {
      question: "Does Host Welcome offer WordPress optimization with shared hosting?",
      answer: "Yes! Our WordPress-optimized shared hosting includes pre-installed CMS, automatic updates, and specialized caching for faster load times. Boost your SEO rankings with our integrated performance tools. Check our [WordPress Hosting](/wordpress-hosting) guide to maximize your site's potential."
    },
    {
      question: "How do I migrate my existing website to Host Welcome shared hosting?",
      answer: "We offer free website migration with zero downtime through our expert team or user-friendly migration plugin. Our step-by-step [Migration Guide](/migration-support) helps transfer files, databases, and emails securely. Focus on your business while we handle the technical details!"
    },
    {
      question: "What makes Host Welcome's shared hosting environmentally friendly?",
      answer: "Our green hosting initiative uses energy-efficient servers and carbon offset programs. Choose Host Welcome for eco-conscious web hosting without compromising performance. Learn about our [Sustainability Efforts](/green-hosting) and contribute to a greener web."
    },
    {
      question: "Does shared hosting include email accounts and professional addresses?",
      answer: "Yes! All Host Welcome shared hosting plans include unlimited professional email accounts (you@yourdomain.com) with spam protection and webmail access. Enhance your brand credibility through our [Business Email Solutions](/professional-email)."
    },
    {
      question: "How does Host Welcome optimize shared hosting for ecommerce websites?",
      answer: "Our ecommerce-ready hosting includes free SSL, PCI compliance, and WooCommerce optimization. Ensure secure transactions and smooth shopping experiences with our [Online Store Hosting](/ecommerce-hosting) packages."
    },
    {
      question: "What SEO tools come with Host Welcome shared hosting plans?",
      answer: "Boost your search rankings with integrated SEO tools including SSL certificates, speed optimization, and clean URL structures. Our hosting environment supports popular SEO plugins and provides free access to [SEO Starter Guides](/seo-resources)."
    },
    {
      question: "How does Host Welcome's 99.9% uptime guarantee benefit my website?",
      answer: "Our premium network infrastructure and server monitoring ensure maximum availability for your website. The uptime guarantee means consistent accessibility for visitors and better search engine rankings. View our [Service Level Agreement](/sla) for details."
    },
    {
      question: "Can I host multiple websites on a single shared hosting plan?",
      answer: "Our premium shared hosting plans allow unlimited websites with separate domains. Manage all your web projects through a single control panel. Explore [Multi-Site Hosting Options](/multiple-websites) for efficient digital management."
    },
    {
      question: "What backup solutions are included with shared hosting?",
      answer: "Protect your data with automatic daily backups and one-click restore functionality. Host Welcome maintains 30-day backup archives for easy recovery. Learn about our [Backup Protocols](/backup-solutions) and disaster recovery processes."
    }
  ];
  const hostingFeatures = [
    {
      title: "Basic Web Hosting",
      description:
        "PHP-based web hosting is the most widely used solution by people worldwide to host their websites. Whether it's a personal website or websites developed with WordPress, Joomla, Drupal, or Magento, you cannot go wrong with cPanel-based Linux hosting with PHP support.",
      icon: <FaGlobe />,
      button: "Get Started",
      link: "/basic-web-hosting", 
    },
    {
      title: "Business Hosting",
      description:
        "Business packages are created for websites with higher resource requirements due to heavy traffic that are no longer suitable for regular packages. Websites developed with WordPress, Magento with thousands of visitors will find better results at a much lower cost than an expensive VPS.",
      icon: <FaBusinessTime />,
      button: "View Plans",
      link: "/business-hosting", 
    },
  ];

  const features = [
    {
      id: 1,
      title: "Affordable & Cost-Effective",
      description:
        "Basic Web hosting is the most budget-friendly option for websites. It provides reliable performance without the high cost of dedicated servers, making it ideal for startups, bloggers, and small businesses.",
      icon: <BiSolidDollarCircle />,
    },
    {
      id: 2,
      title: "User-Friendly cPanel",
      description:
        "Easily manage your website, domains, emails, and databases with a simple and intuitive cPanel interface. No technical expertise required!",
      icon: <FaServer />,
    },

    {
      id: 4,
      title: "99.9% Uptime Guarantee",
      description:
        "Our shared hosting servers are optimized for maximum uptime, ensuring your website stays online with minimal interruptions.",
      icon: <FaGlobe />,
    },
    {
      id: 5,
      title: "24/7 Expert Support",
      description:
        "Need help? Our expert support team is available 24/7 to assist you with any hosting-related issues.",
      icon: <MdSupportAgent />,
    },
    {
      id: 6,
      title: "Fast Loading Speeds",
      description:
        "Our shared hosting uses SSD storage and LiteSpeed web servers for ultra-fast loading times, improving user experience and SEO rankings.",
      icon: <FaBolt />,
    },
  ];
  
  return (
<>
<Helmet>
        <title>Best Shared Hosting Plans in Pakistan - Affordable & Fast | Host Welcome</title>
        <meta 
          name="description" 
          content="Choose from our budget-friendly web hosting plans in Pakistan. Shared hosting, VPS, and dedicated servers with free SSL, cPanel, and 24/7 support. Get high-speed hosting with guaranteed uptime." 
        />
      </Helmet>
<header>
<div className='shared-head-banner d-flex
'>
    <span><FaServer /><p>Basic Web Hosting</p></span>
</div>
</header>
          <div className='number-bar'>
          <p className='pe-4'>Have any Question?</p>
          <p className='pe-4'>We have Precise answer. </p>
          <IoMdContacts />
          
          <p>+92 3242163697</p>
          </div>


<SharedHostingFeature hostingFeatures={hostingFeatures}/>
 <HomePricingComparision HomePlans={HomePlans}/>
 <VPSFeatures title="Why Choose Basic Web Hosting" features={features}/>
<br/>


<br/>

<DemoBox/>
<br/>
 <WhyChooseUs/>
 <Topfeature/>
<QASection questions={questions}/>
</>
  )
}
