import React from "react";
import { Link } from "react-router-dom"; 
import "./Footer.css"; 
import { FaGlobe, FaServer, FaLock, FaInfoCircle, FaMapMarkerAlt, FaPhone, FaEnvelope, FaShieldAlt, FaLinux, FaWindows, FaKey, FaBriefcase, FaWordpressSimple, FaGlobeAmericas, FaGlobeEurope } from "react-icons/fa";
import { FaGears } from "react-icons/fa6";
import { SiCpanel } from "react-icons/si";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { GoServer } from "react-icons/go";
import SocialIcons from "./SocialIcons";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-column">
            <h3><FaGlobe /> Domain</h3>
            <ul>
              <li><Link to="/domain-registration"><FaGlobeEurope /> Domain Registration</Link></li>
              <li><Link to="/pk-domain-registration"><FaGlobeAmericas /> PK Domains</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3><FaServer /> Web Hosting</h3>
            <ul>
              <li><Link to="/basic-web-hosting"><GoServer /> Basic Web Hosting</Link></li>
              <li><Link to="/wordpress-hosting"><FaWordpressSimple /> WordPress Hosting</Link></li>
              <li><Link to="/business-hosting"><FaBriefcase /> Business Hosting</Link></li>
              <li><Link to="/reseller-hosting"><SiCpanel /> Reseller Hosting</Link></li>
              <li><Link to="/rootreseller-hosting"><FaKey /> Root Reseller</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3><FaServer /> Virtual Servers</h3>
            <ul>
              <li><Link to="/windows-vps"><FaWindows /> Windows VPS Server</Link></li>
              <li><Link to="/linux-vps"><FaLinux /> Linux VPS Server</Link></li>
              <li><Link to="/dedicated-server"><FaServer /> Dedicated Server</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3><FaLock /> Services</h3>
            <ul>
              <li><Link to="/ssl-certificate"><FaShieldAlt /> SSL Certificate</Link></li>
              <li><Link to="/server-management"><FaGears /> Server Management</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3><FaInfoCircle /> About</h3>
            <ul>
              <li><Link to="/aboutus"><IoMdInformationCircleOutline /> About Us</Link></li>
              <li><Link to="/contact"><IoMdInformationCircleOutline /> Contact Us</Link></li>
              <li><Link to="/terms"><IoMdInformationCircleOutline /> Terms & Conditions</Link></li>
            </ul>
          </div>
          <div className="footer-column large">
            <h3><FaMapMarkerAlt /> Reach Us</h3>
            <p>Office: Manzoor Colony Sector I Shop No.542 Karachi, Pakistan</p>
            <p><FaPhone /> +92 324 216 3597</p>
            <p><FaEnvelope /> info@HostWelcome.com</p>
          </div>
        </div>
      </footer>
      <section className="footer-bottom">
        <h2>© 2013 - 2025 HostWelcome Private Limited | All Rights Reserved.</h2>
        <div className="footer-socialicons"><SocialIcons /></div>
      </section>
    </>
  );
};

export default Footer;
