import React from 'react'
import { FaServer } from "react-icons/fa";
import { IoMdContacts } from "react-icons/io";
import { Link } from 'react-router-dom';
import SocialIcons from '../components/SocialIcons';
import CustomerReviews from '../components/CustomerReviews';
import { Helmet } from 'react-helmet';
export default function Contactus() {
  return (
  <>
        <Helmet>
        <title>Contact Host Welcome - 24/7 Web Hosting Support in Pakistan</title>
        <meta 
          name="description" 
          content="Need help? Contact Host Welcome for web hosting support, domain registration, and server management. Available 24/7 via phone, email, and live chat." 
        />
      </Helmet>

  <header>
  <div className='shared-head-banner d-flex
  '>
      <span><FaServer /><p>Contact Us </p></span>
  </div>
  </header>
  <div className='number-bar'>
  <p className='pe-4'>Have any Question?</p>
  <p className='pe-4'>We have Precise answer. </p>
  <IoMdContacts />
  
  <p>+92 3242163697</p>
  </div>

  <div className="support-section2">
      <h2>Dedicated Support</h2>
      <p>We don’t want our customers to keep waiting for support. We believe that live chat, WhatsApp, and other means of internet communication can solve problems more quickly and efficiently.</p>

      <h3>How We Can Assist You?</h3>
      <p><strong>Already a client?</strong> <a href="/login" className="link">Login</a> and reach our Customer Support Team.</p>

      <h3>Sales Inquiry</h3>
      <p>Email us at: <a href="mailto:sales@HostWelcome.com" className="link">sales@HostWelcome.com</a></p>

      <h3>Lost Access to your HostWelcome account?</h3>
      <p><Link to="/reset-password" className="link">Reset your password here</Link></p>


    
      <div>
        <SocialIcons/>
    </div>

    </div>
    <CustomerReviews/>



  
    <div className='location-point'>
    <iframe  title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d761.1396239707692!2d67.08110623235066!3d24.842986107347777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33d11a9bee31b%3A0x2ca24cc47599b997!2sTech%20Care%20Solution!5e0!3m2!1sen!2s!4v1737973181609!5m2!1sen!2s" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </>
  )
}
