import { BadgeCheck, Globe2, HardDrive, Headphones, PiggyBank, Server, Users, Wallet } from "lucide-react"
import "./WhyChooseUs.css"

const features = [
  {
    icon: PiggyBank,
    title: "30 Days Moneyback Guarantee",
    gradient: "from-emerald-400 to-teal-600",
  },
  {
    icon: Headphones,
    title: "Phone, Email, Chat & Whatsapp Support",
    gradient: "from-teal-400 to-cyan-600",
  },
  {
    icon: HardDrive,
    title: "Enterprise RAID 10 Drives",
    gradient: "from-cyan-400 to-emerald-600",
  },
  {
    icon: Server,
    title: "In Business Since 2006 with more than 24000+ Sites",
    gradient: "from-emerald-400 to-teal-600",
  },
  {
    icon: BadgeCheck,
    title: "100% UpTime Guaranteed",
    gradient: "from-teal-400 to-cyan-600",
  },
  {
    icon: Globe2,
    title: "Free Domain Registration",
    gradient: "from-cyan-400 to-emerald-600",
  },
  {
    icon: Wallet,
    title: "No Hidden Charges",
    gradient: "from-emerald-400 to-teal-600",
  },
  {
    icon: Users,
    title: "Experienced & Friendly Support Staff",
    gradient: "from-teal-400 to-cyan-600",
  },
]

const WhyChooseUs = () => {
  return (
    <div className="whychooseus">
    <section className="features-section">
      <div className="container">
        <h2 className="section-title">Why Choose Us</h2>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              <div className={`icon-wrapper ${feature.gradient}`}>
                <feature.icon className="icon" />
                <div className="glow"></div>
              </div>
              <h3 className="feature-title">{feature.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
    </div>
  )
}

export default WhyChooseUs

