import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

const QASection = ({questions}) => {
  const [activeIndex, setActiveIndex] = useState(null);



  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="qa-container container">
      {questions.map((item, index) => (
        <div className="qa-item" key={index}>
          <div
            className={`qa-header ${activeIndex === index ? "active" : ""}`}
            onClick={() => toggleAnswer(index)}
          >
            <h3 className="question">{item.question}</h3>
            <span className="icon">
              {activeIndex === index ? <FaMinus /> : <FaPlus />}
            </span>
          </div>
          <div
            className={`answer ${activeIndex === index ? "open" : ""}`}
            style={{ maxHeight: activeIndex === index ? "500px" : "0px" }}
          >
            {item.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default QASection;
